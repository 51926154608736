import { ISearchReportTypeOption } from 'store/types/slicesTypes/search'

export const useSearchReportTypeOptions = () => {
  const searchReportType: ISearchReportTypeOption[] = [
    {
      key: 'flashReport',
      displayName: 'Flash',
    },
    {
      key: 'monthlyReport',
      displayName: 'Monthly',
    },
    {
      key: 'CTRReport',
      displayName: 'CTR',
    },
    {
      key: 'cyberAffairs',
      displayName: 'Cyber Affairs',
    },
    {
      key: 'VIRReport',
      displayName: 'VIR',
    },
    {
      key: 'customReport',
      displayName: 'Custom Report',
    },
    {
      key: 'ddwTailoredReport',
      displayName: 'DDW Tailored',
    },
  ]

  return searchReportType
}
