import { Box, Stack } from '@mui/material'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H3Title } from 'components/Documents/H3Title'
import { DateLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import {
  ListItem,
  NumberListItem,
  OrderedList,
  UnorderedList,
} from 'components/List/List'
import { DocumentTimestamp } from 'constants/document'
import { PAGE_TITLE_MFA_INFORMATION } from 'constants/pageTitle'
import { useDateTime } from 'hooks/useDatetime'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { openMail } from 'utils/mail'

export const MFAInformationPage = () => {
  const { t } = useTranslation(['document'])
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_MFA_INFORMATION}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Stack sx={{ gap: 2, pt: 4, pb: 10, width: '40.625rem' }}>
            <H1Title title={t('mfaDocument.title', { ns: 'document' })} />
            <DateLabel
              text={getDateStringByTimestampsInSeconds(
                DocumentTimestamp.MFA_INFORMATION_TIMESTAMP
              )}
            />
            <H3Title
              title={t('mfaDocument.explanation.title', { ns: 'document' })}
            />
            <Description>
              {t('mfaDocument.explanation.description', { ns: 'document' })}
            </Description>
            <Description>
              {t('mfaDocument.appTitle', { ns: 'document' })}
            </Description>
            <UnorderedList>
              <ListItem>Google Authenticator</ListItem>
              <ListItem>Authenticator Extension</ListItem>
              <ListItem>1Password</ListItem>
              <ListItem>Authy</ListItem>
              <ListItem>Microsoft Authenticator</ListItem>
            </UnorderedList>
            <Description>
              {t('mfaDocument.otherAppDescription', { ns: 'document' })}
            </Description>
            <H3Title title={t('mfaDocument.setUp.title', { ns: 'document' })} />
            <OrderedList>
              <NumberListItem>
                {t('mfaDocument.setUp.step1', { ns: 'document' })}
              </NumberListItem>
              <NumberListItem>
                {t('mfaDocument.setUp.step2', { ns: 'document' })}
              </NumberListItem>
              <NumberListItem>
                {t('mfaDocument.setUp.step3', { ns: 'document' })}
              </NumberListItem>
              <NumberListItem>
                {t('mfaDocument.setUp.step4', { ns: 'document' })}
              </NumberListItem>
            </OrderedList>
            <H3Title
              title={t('mfaDocument.changeApp.title', { ns: 'document' })}
            />
            <Description>
              <Trans t={t} i18nKey="mfaDocument.changeApp.description">
                Go to the “Two-Factor Authentication Settings” in “Account
                Settings” and click “Change Authenticator.” You can then set up
                2FA with a new authenticator app without any access
                interruptions.
                <br />
                After resetting, you will no longer be able to use the previous
                authenticator app to obtain verification codes or perform 2FA on
                your ThreatVision account.
              </Trans>
            </Description>
            <H3Title
              title={t('mfaDocument.problem.title', { ns: 'document' })}
            />
            <Description>
              <Trans t={t} i18nKey="mfaDocument.problem.description">
                Contact your sales representative immediately for professional
                support.
                <TextLink size="medium" onClick={openMail} to="">
                  support@teamt5.org
                </TextLink>
              </Trans>
            </Description>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
