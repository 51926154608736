import { FormControlLabel, Stack, Typography, useTheme } from '@mui/material'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { Dialog } from 'components/Dialog/Dialog'
import { TextLink } from 'components/Link/Link'
import { MFA_INFORMATION_ROUTE } from 'constants/routeParams'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ignoreAccountMFAPopup } from 'store/slices/account'

interface IInfoDialogProps {
  open: boolean
  handleClose: () => void
  handleConfirm: () => void
}

export const InfoDialog = ({
  open,
  handleClose,
  handleConfirm,
}: IInfoDialogProps) => {
  const { t } = useTranslation(['mfa'])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [isNotShow, setIsNotShow] = useState(false)

  const handleNotShow = () => {
    setIsNotShow((prevIsNotShow) => !prevIsNotShow)
  }

  const handleDialogClose = () => {
    handleClose()
    if (isNotShow) {
      dispatch(ignoreAccountMFAPopup())
    }
  }

  const handleDialogConfirm = () => {
    handleConfirm()
    if (isNotShow) {
      dispatch(ignoreAccountMFAPopup())
    }
  }

  return (
    <Dialog
      open={open}
      handleDialogClose={handleDialogClose}
      title={t('infoDialog.title', { ns: 'mfa' })}
      content={
        <Stack sx={{ gap: 6 }}>
          <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
            <Trans t={t} i18nKey="infoDialog.description">
              Do you want to implement 2FA to strengthen data security
              protection? Please begin the two-factor authentication setup
              process.
              <TextLink to={`/${MFA_INFORMATION_ROUTE}`} target="_blank">
                What is two-factor authentication?
              </TextLink>
            </Trans>
          </Typography>
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': {
                color: theme.colors.WHITE,
                ...theme.typography.textSmallImportant,
              },
            }}
            control={<Checkbox checked={isNotShow} onChange={handleNotShow} />}
            label={
              <Typography sx={{ color: theme.colors.WHITE }} variant="body">
                {t('infoDialog.notShow', { ns: 'mfa' })}
              </Typography>
            }
          />
        </Stack>
      }
      cancelButtonText={t('infoDialog.cancelCta', { ns: 'mfa' })}
      confirmButtonText={t('infoDialog.confirmCta', { ns: 'mfa' })}
      handleConfirmButtonClick={handleDialogConfirm}
    />
  )
}
