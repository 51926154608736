import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { MalwareTypeLabel } from 'components/Label/Label'
import { MALWARE_CONTENT_ROUTE, MALWARES_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useTranslation } from 'react-i18next'
import { IMalware } from 'store/types/entityTypes/malware'

import {
  MalwareAliasList,
  MalwareAttribution,
  MalwareDescription,
  MalwareThreatHuntingTools,
} from './MalwareLabels'

interface IMalwarePreviewProps extends IMalware {
  showDescription?: boolean
  showTools?: boolean
  showCheckDetailButton?: boolean
  showHoverStyle?: boolean
}

export const MalwarePreview = ({
  id,
  name,
  aliases,
  type,
  attribution,
  hasTools,
  overview,
  showDescription = false,
  showTools = false,
  showCheckDetailButton = false,
  showHoverStyle = false,
}: IMalwarePreviewProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  return (
    <Box
      sx={{
        px: 4,
        py: 3,
        '&:hover': showHoverStyle ? { bgcolor: theme.colors.BLACK_60 } : {},
        '&:active': showHoverStyle ? { bgcolor: theme.colors.BLACK_95 } : {},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          color: theme.colors.WHITE,
          justifyContent: 'space-between',
        }}
      >
        <Stack sx={{ gap: 2 }}>
          <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
              {name}
            </Typography>
            {type && <MalwareTypeLabel type={type} />}
          </Box>
          <MalwareAliasList aliases={aliases} />
          <MalwareAttribution attribution={attribution} />
          {showDescription && <MalwareDescription overview={overview} />}
          {showTools && (
            <MalwareThreatHuntingTools id={id} hasTools={hasTools} />
          )}
        </Stack>
      </Box>
      {showCheckDetailButton && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
          <LinkButton
            to={`/${MALWARES_ROUTE}/${MALWARE_CONTENT_ROUTE}?${SearchParamKey.MALWARE_ID}=${id}`}
            endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
          >
            {t('readMoreCta', { ns: 'component' })}
          </LinkButton>
        </Box>
      )}
    </Box>
  )
}
