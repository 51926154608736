import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'
import { DDW_TAILORED_REPORT_KEY } from 'constants/ddw'
import { PAGE_TITLE_REPORT_CYBER_AFFAIRS } from 'constants/pageTitle'
import { NOT_FOUND_ROUTE } from 'constants/routeParams'
import { useDateRangeOptions } from 'hooks/useDateRangeOptions'
import { TRangeOption, useDateTime } from 'hooks/useDatetime'
import { useIsScrollable } from 'hooks/useIsScrollable'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { DDW_TAILORED_REPORT_TYPE } from 'pages/ReportsPage/constants/reportType'
import { FilterBar } from 'pages/ReportsPage/ReportComponents/FilterBar'
import { ReportListInfo } from 'pages/ReportsPage/ReportComponents/ReportListInfo'
import { ReportListLoading } from 'pages/ReportsPage/ReportComponents/ReportLoading'
import { ReportPreview } from 'pages/ReportsPage/ReportComponents/ReportPreview'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectAccountSettings } from 'store/slices/account'
import {
  fetchDDWTailoredReports,
  fetchDDWTailoredReportsCancelled,
  resetDDWTailoredReport,
  selectDDWTailoredReport,
  selectHasMoreDDWTailoredReport,
  selectIsFetchDDWTailoredReportLoading,
} from 'store/slices/report'

const SCROLLABLE_CONTAINER_ID = 'cyber-affairs-report-list-container'

export const DDWTailoredReportsPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { dateRangeOptionToTimes } = useDateTime()
  const rangeOptions = useDateRangeOptions()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['report', 'component'])
  const ddwTailoredReports = useAppSelector(selectDDWTailoredReport)
  const hasMoreReports = useAppSelector(selectHasMoreDDWTailoredReport)
  const isFetchDDWTailoredReportLoading = useAppSelector(
    selectIsFetchDDWTailoredReportLoading
  )
  const accountSettings = useAppSelector(selectAccountSettings)
  const subscribeDdwTailoredReport =
    accountSettings.otherServicesSubscriberGroups?.find(
      (otherServicesSubscriberGroup) =>
        otherServicesSubscriberGroup.key === DDW_TAILORED_REPORT_KEY
    )

  const [isScrollable, ref, node] = useIsScrollable([ddwTailoredReports])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [rangeOption, setRangeOption] = useState<string>(rangeOptions[0].value)

  const fetchMoreReports = (refetch?: boolean) => {
    const offset = refetch ? 0 : ddwTailoredReports.length
    dispatch(
      fetchDDWTailoredReports({
        offset,
        ...dateRangeOptionToTimes({ startDate, endDate })[
          rangeOption as TRangeOption
        ],
      })
    )
  }

  const isFilter =
    startDate !== null ||
    endDate !== null ||
    rangeOption !== rangeOptions[0].value

  useEffect(() => {
    if (
      accountSettings.otherServicesSubscriberGroups !== null &&
      !subscribeDdwTailoredReport
    )
      navigate(`/${NOT_FOUND_ROUTE}`)
  }, [accountSettings])

  // initial fetch
  useEffect(() => {
    fetchMoreReports(true)

    return () => {
      dispatch(fetchDDWTailoredReportsCancelled())
      dispatch(resetDDWTailoredReport())
    }
  }, [rangeOption, startDate, endDate])

  // infinite scroll fetch
  useEffect(() => {
    if (node && !isScrollable && hasMoreReports) {
      fetchMoreReports()
    }
  }, [isScrollable, hasMoreReports, node, ddwTailoredReports.length])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_REPORT_CYBER_AFFAIRS}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          border: `1px solid ${theme.colors.WHITE_20}`,
          borderRadius: 0.5,
          color: theme.colors.WHITE,
        }}
      >
        {/* ddwTailoredReports no otherReportsInfo */}
        <ReportListInfo type={DDW_TAILORED_REPORT_TYPE} />
        <Stack
          sx={{
            flexGrow: 1,
            flexBasis: 0,
            width: '100%',
            maxWidth: '85rem',
            minWidth: '47.5rem',
            [theme.breakpoints.up('xl')]: {
              minWidth: '85rem',
            },
          }}
        >
          <FilterBar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            rangeOption={rangeOption}
            setRangeOption={setRangeOption}
          />
          {isFetchDDWTailoredReportLoading &&
          ddwTailoredReports.length === 0 ? (
            <ReportListLoading />
          ) : (
            <Stack
              id={SCROLLABLE_CONTAINER_ID}
              ref={ref}
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                overflowY: 'auto',
              }}
            >
              {ddwTailoredReports.length > 0 ? (
                <>
                  <InfiniteScrollWrapper
                    dataLength={ddwTailoredReports.length}
                    next={fetchMoreReports}
                    hasMore={hasMoreReports}
                    scrollableTarget={SCROLLABLE_CONTAINER_ID}
                  >
                    {ddwTailoredReports.map((report) => (
                      <Box key={report.id}>
                        <ReportPreview {...report} hasImage />
                        <Divider sx={{ borderColor: theme.colors.WHITE_20 }} />
                      </Box>
                    ))}
                  </InfiniteScrollWrapper>
                  <ScrollToTop
                    scrollableContainerId={SCROLLABLE_CONTAINER_ID}
                    sx={{ position: 'sticky', bottom: '2%', left: '95%' }}
                  />
                </>
              ) : (
                <Box sx={{ p: 4 }}>
                  <Typography
                    variant="titleSmall"
                    sx={{ color: theme.colors.WHITE_60 }}
                  >
                    {isFilter
                      ? t('emptyState.noMatchFilter', { ns: 'component' })
                      : t('emptyState.noReports', {
                          ns: 'report',
                          reports: t('ddwTailoredReports.title', {
                            ns: 'report',
                          }),
                        })}
                  </Typography>
                </Box>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  )
}
