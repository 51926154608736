import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { ReactComponent as BadIcon } from 'assets/basicIcons/bad.svg'
import { ReactComponent as GoodIcon } from 'assets/basicIcons/good.svg'
import { ReactComponent as InfoIcon } from 'assets/basicIcons/info.svg'
import { ReactComponent as ReportIcon } from 'assets/report/icons/report.svg'
import { Button } from 'components/Button/Button'
import { KeyDialog } from 'components/MfaSetupDialog/KeyDialog'
import { OtpDialog } from 'components/MfaSetupDialog/OtpDialog'
import { PageSection } from 'components/PageSection/PageSection'
import { MFADialogStep } from 'constants/mfa'
import { MFA_INFORMATION_ROUTE } from 'constants/routeParams'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchAccountMFASettings,
  fetchAccountMFASettingsCancelled,
  selectAccountMFASettings,
  selectIsAccountMFASettingsLoading,
} from 'store/slices/account'

import { MFADrawer } from './MFADrawer'
import { MFASettingsLoading } from './MFASettingsLoading'

export const MFASettings = () => {
  const { t } = useTranslation(['account'])
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const accountMFASettings = useAppSelector(selectAccountMFASettings)
  const isAccountMFASettingsLoading = useAppSelector(
    selectIsAccountMFASettingsLoading
  )

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [mfaDialogStep, setMfaDialogStep] = useState<MFADialogStep | null>(null)

  const handleMfaDialogStep = (step: MFADialogStep | null) => {
    setMfaDialogStep(step)
  }

  const MFAExist = accountMFASettings?.mfaEnable !== null

  const toggleDrawer = () => {
    setDrawerOpen((open) => !open)
  }

  const handleMFASettingsClick = () => {
    if (MFAExist) {
      toggleDrawer()
    } else {
      handleMfaDialogStep(MFADialogStep.KEY)
    }
  }

  useEffect(() => {
    dispatch(fetchAccountMFASettings())

    return () => {
      dispatch(fetchAccountMFASettingsCancelled())
    }
  }, [])

  return (
    <PageSection
      title={t('mfa.title', { ns: 'account' })}
      titleAction={
        <Button variant="textColor" href={`/${MFA_INFORMATION_ROUTE}`}>
          <SvgIcon
            component={ReportIcon}
            inheritViewBox
            sx={{
              path: { fill: theme.colors.TEXT_LINK_NORMAL },
              width: '1rem',
              height: '1rem',
            }}
          />
          {t('mfa.documentCta', { ns: 'account' })}
        </Button>
      }
    >
      {isAccountMFASettingsLoading ? (
        <MFASettingsLoading />
      ) : (
        accountMFASettings && (
          <Box
            sx={{
              height: '100%',
              px: 4,
              py: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="textSmallImportant"
              color={theme.colors.WHITE_60}
              sx={{ flexGrow: 1 }}
            >
              {t('mfa.description', { ns: 'account' })}
            </Typography>
            <Box sx={{ flexBasis: '12.5rem', flexShrink: 0 }}>
              {accountMFASettings.mfaEnable === true && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <SvgIcon
                    component={GoodIcon}
                    inheritViewBox
                    sx={{
                      path: { fill: theme.colors.TLPGreen },
                      width: '1rem',
                      height: '1rem',
                    }}
                  />
                  <Typography variant="bodySmall" color={theme.colors.WHITE}>
                    {t('mfa.alreadyEnabled', { ns: 'account' })}
                  </Typography>
                </Box>
              )}
              {accountMFASettings.mfaEnable === null && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <SvgIcon
                    component={InfoIcon}
                    inheritViewBox
                    sx={{
                      path: { fill: theme.colors.WHITE_60 },
                      width: '1rem',
                      height: '1rem',
                    }}
                  />
                  <Typography
                    variant="bodySmall"
                    color={theme.colors.WHITE}
                    sx={{ flexBasis: '12.5rem', flexShrink: 0 }}
                  >
                    {t('mfa.notYetConfigured', { ns: 'account' })}
                  </Typography>
                </Box>
              )}
              {accountMFASettings.mfaEnable === false && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <SvgIcon
                    component={BadIcon}
                    inheritViewBox
                    sx={{
                      path: { fill: theme.colors.TLPRed },
                      width: '1rem',
                      height: '1rem',
                    }}
                  />
                  <Typography
                    variant="bodySmall"
                    color={theme.colors.WHITE}
                    sx={{ flexBasis: '12.5rem', flexShrink: 0 }}
                  >
                    {t('mfa.notEnabled', { ns: 'account' })}
                  </Typography>
                </Box>
              )}
            </Box>
            <Button
              size="small"
              endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
              onClick={handleMFASettingsClick}
            >
              {MFAExist
                ? t('mfa.updateCta', { ns: 'account' })
                : t('mfa.createCta', { ns: 'account' })}
            </Button>
          </Box>
        )
      )}
      <MFADrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      {mfaDialogStep === MFADialogStep.KEY && (
        <KeyDialog
          open={mfaDialogStep === MFADialogStep.KEY}
          handleClose={() => handleMfaDialogStep(null)}
          handleConfirm={() => handleMfaDialogStep(MFADialogStep.OTP)}
        />
      )}
      {mfaDialogStep === MFADialogStep.OTP && (
        <OtpDialog
          open={mfaDialogStep === MFADialogStep.OTP}
          handleClose={() => handleMfaDialogStep(null)}
          handleBack={() => handleMfaDialogStep(MFADialogStep.KEY)}
        />
      )}
    </PageSection>
  )
}
