export const PAGE_TITLE_LANDING = 'ThreatVision'
export const PAGE_TITLE_REPORT_APT = 'APT - Reports - ThreatVision'
export const PAGE_TITLE_REPORT_FLASH = 'Flash Report - Reports - ThreatVision'
export const PAGE_TITLE_REPORT_MONTHLY =
  'Monthly Report - Reports - ThreatVision'
export const PAGE_TITLE_REPORT_CTR =
  'Campaign Tracking Report - Reports - ThreatVision'
export const getPageTitleReportDetailByNameAndType = (
  reportName: string,
  reportTypeText: string
) => `${reportName} - ${reportTypeText} - ThreatVision`
export const PAGE_TITLE_REPORT_CYBER_AFFAIRS =
  'Cyber Affairs Report - Reports - ThreatVision'
export const PAGE_TITLE_REPORT_VULNERABILITY =
  'Vulnerability - Reports - ThreatVision'
export const PAGE_TITLE_REPORT_VMRR =
  'Vulnerability Insights Report - Reports - ThreatVision'
export const PAGE_TITLE_REPORT_PMR =
  'Patch Management Report - Reports - ThreatVision'
export const getPageTitlePMRReportDetailByName = (reportName: string) =>
  `${reportName} - Patch Management Report - ThreatVision`
export const PAGE_TITLE_SEARCH = 'Search - ThreatVision'
export const getPageTitleSearchResultByText = (searchText: string) =>
  `${searchText} - Search - ThreatVision`
export const getPageTitleRawIntelDetailByTitle = (rawIntelTitle: string) =>
  `${rawIntelTitle} - Raw Intel - ThreatVision`
export const PAGE_TITLE_THREAT_LANDSCAPE = 'Threat Landscape - ThreatVision'
export const getPageTitleThreatLandscapeRegionByName = (regionName: string) =>
  `${regionName} - Threat Landscape - ThreatVision`
export const getPageTitleThreatLandscapeCountryByName = (countryName: string) =>
  `${countryName} - Threat Landscape - ThreatVision`
export const getPageTitleThreatLandscapeIndustryByName = (
  industryName: string
) => `${industryName} - Threat Landscape - ThreatVision`
export const PAGE_TITLE_ADVERSARIES = 'Adversaries - ThreatVision'
export const getPageTitleAdversaryDetailByName = (adversaryName: string) =>
  `${adversaryName} - Adversaries - ThreatVision`
export const PAGE_TITLE_MALWARE = 'Malware - ThreatVision'
export const getPageTitleMalwareDetailByName = (malwareName: string) =>
  `${malwareName} - Malware - ThreatVision`
export const PAGE_TITLE_SAMPLE_MANAGEMENT = 'Sample Management - ThreatVision'
export const getPageTitleSampleDetailByHash = (sampleHash: string) =>
  `${sampleHash} - Samples - ThreatVision`
export const getPageTitleIPDetailByAddress = (address: string) =>
  `${address} - IPs - ThreatVision`
export const getPageTitleDomainDetailByFQDN = (fqdn: string) =>
  `${fqdn} - Domains - ThreatVision`
export const PAGE_TITLE_CAPABILITIES = 'Capabilities - ThreatVision'
export const getPageTitleTacticDetailByTitle = (tacticTitle: string) =>
  `${tacticTitle}, Tactic - Capabilities - ThreatVision`
export const getPageTitleTechniqueDetailByTitle = (techniqueTitle: string) =>
  `${techniqueTitle}, Technique - Capabilities - ThreatVision`
export const PAGE_TITLE_DOWNLOADS = 'Downloads - ThreatVision'
export const PAGE_TITLE_ACCOUNT = 'Account - ThreatVision'
export const PAGE_TITLE_ACCOUNT_RFI_LIST = 'RFI List - Account - ThreatVision'
export const getPageTitleAPIDocumentByTitle = (title: string) =>
  `${title} - API Document - ThreatVision`
export const PAGE_TITLE_DOCUMENTS = 'Documents - ThreatVision'
export const PAGE_TITLE_REPORT_INTRODUCTION =
  'Report Introduction - ThreatVision'
export const getPageTitleReportIntroductionByTitle = (title: string) =>
  `${title} - ${PAGE_TITLE_REPORT_INTRODUCTION}`
export const PAGE_TITLE_MFA_INFORMATION = '2FA Introduction - ThreatVision'
export const PAGE_TITLE_EULA_ENGLISH =
  'End User License Agreement English Version - ThreatVision'
export const PAGE_TITLE_EULA_CHINESE =
  'End User License Agreement Traditional Chinese Version - ThreatVision'
export const PAGE_TITLE_TERMS_OF_SERVICE = 'Terms of Service - ThreatVision'
export const PAGE_TITLE_PRIVACY_POLICY = 'Privacy Policy - ThreatVision'
export const PAGE_TITLE_LOGIN = 'Login - ThreatVision'
export const PAGE_TITLE_RESET_PASSWORD = 'Reset Password - ThreatVision'
export const PAGE_TITLE_ERROR_404 = 'Error 404 (Not Found) - ThreatVision '
export const PAGE_TITLE_ERROR_500 = 'Error 500 (Server Error) - ThreatVision'
export const PAGE_TITLE_WEBSITE_MAINTENANCE =
  'Website Maintenance - ThreatVision'
export const PAGE_TITLE_RISK_MONITORING = 'Risk Monitoring - ThreatVision'
export const PAGE_TITLE_DDW_ALERT = 'Deep and Dark Web Alert - ThreatVision'
export const PAGE_TITLE_DDW_ALERT_DEMO =
  'Deep and Dark Web Alert Demo - ThreatVision'
export const PAGE_TITLE_DDW_TAILORED_CHINESE_SAMPLE_REPORT =
  'Deep and Dark Web Tailored Traditional Chinese Sample Report - ThreatVision'
export const PAGE_TITLE_DDW_TAILORED_ENGLISH_SAMPLE_REPORT =
  'Deep and Dark Web Tailored Traditional English Sample Report - ThreatVision'
