export enum MFADialogStep {
  INFO = 'info',
  KEY = 'key',
  OTP = 'otp',
  UPDATE_WARNING = 'update-warning',
}

export enum MFAKeyDialogTab {
  QR_CODE = 'qr-code',
  KEY = 'key',
}
