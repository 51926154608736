import { Box, Stack, Typography, useTheme } from '@mui/material'
import DefaultCoverImage from 'assets/report/default-cover.jpg'
import UnsubscribeCoverImage from 'assets/report/unsubscribe-cover.jpg'
import UnsubscribeMaskImage from 'assets/report/unsubscribe-mask.png'
import { DateLabel } from 'components/Label/Label'
import { LabelList } from 'components/LabelList/LabelList'
import {
  CAPABILITY_ROUTE,
  REPORT_DETAIL_ROUTE,
  REPORTS_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
  TECHNIQUE_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { DDW_TAILORED_REPORT_TYPE } from 'pages/ReportsPage/constants/reportType'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { IReport } from 'store/types/entityTypes/report'

import { ReportPurchaseCta } from './ReportPurchaseCta'

const TAGS_MAX_COUNT = 5

interface IReportLabelsProps {
  labels: string[]
  labelType: 'country' | 'industry' | 'malware' | 'adversary' | 'capability'
}

const ReportLabels = ({ labels, labelType }: IReportLabelsProps) => {
  const { t } = useTranslation(['component'])

  const labelTypesMap = {
    country: {
      keyText: t('reportPreview.labels.targetCountry', { ns: 'component' }),
      getLink: (label: string) =>
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${label}`,
    },
    industry: {
      keyText: t('reportPreview.labels.targetIndustry', { ns: 'component' }),
      getLink: (label: string) =>
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${label}`,
    },
    malware: {
      keyText: t('reportPreview.labels.malware', { ns: 'component' }),
      getLink: (label: string) =>
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${label}`,
    },
    adversary: {
      keyText: t('reportPreview.labels.adversary', { ns: 'component' }),
      getLink: (label: string) =>
        `/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${label}`,
    },
    capability: {
      keyText: t('reportPreview.labels.capability', { ns: 'component' }),
      getLink: (label: string) =>
        `/${CAPABILITY_ROUTE}/${TECHNIQUE_ROUTE}?${SearchParamKey.CAPABILITY_TECHNIQUE_ID}=${label}`,
    },
  }

  return (
    <LabelList
      labels={labels}
      keyText={labelTypesMap[labelType].keyText}
      getLabelLink={(label: string) => labelTypesMap[labelType].getLink(label)}
      maxCount={TAGS_MAX_COUNT}
    />
  )
}

export const ReportPreview = ({
  alias,
  title,
  type,
  date,
  digest,
  coverUrl,
  targetedCountries,
  targetedIndustries,
  malwares,
  adversaries,
  permission,
  purchasedInformation,
  capability,
}: IReport) => {
  const theme = useTheme()
  const imageUrl = useImageUrl({ fetchImageUrl: coverUrl })
  const { getDateStringByTimestampsInSeconds } = useDateTime()

  let coverImage = permission ? DefaultCoverImage : UnsubscribeCoverImage

  if (permission && imageUrl) {
    coverImage = imageUrl
  }

  return (
    <Stack
      sx={{
        '&:hover': {
          bgcolor: theme.colors.BLACK_60,
          '& .reportTitle': {
            boxShadow: `${theme.colors.PRIMARY_20} 0px -8px 0px 0px inset`,
          },
        },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
    >
      <Stack
        sx={{
          pl: 2,
          pr: 4,
          pt: 2,
          gap: 3,
        }}
        to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${alias}`}
        component={permission ? Link : Stack}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'baseline',
            gap: 2,
          }}
        >
          <Box sx={{ flexShrink: 0, alignSelf: 'center' }}>
            <DateLabel text={getDateStringByTimestampsInSeconds(date)} />
          </Box>
          <Box sx={{ flexGrow: 1, overflowWrap: 'anywhere' }}>
            <Typography
              variant="title"
              className="reportTitle"
              sx={{ color: theme.colors.PRIMARY }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {permission ? (
            <Box>
              <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
                {digest.length > 600 ? `${digest.slice(0, 600)}...` : digest}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Image src={UnsubscribeMaskImage} alt="Unsubscribe" />
              <ReportPurchaseCta
                purchasedInformation={purchasedInformation}
                alias={alias}
              />
            </Box>
          )}
          <Box sx={{ width: '10rem', flexShrink: 0 }}>
            {coverImage && (
              <Image
                src={coverImage}
                alt={title}
                width="10rem"
                height="10rem"
                fit="cover"
              />
            )}
          </Box>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pl: 2, pr: 4, pt: 4, pb: 4 }}>
        {type !== DDW_TAILORED_REPORT_TYPE ? (
          <>
            <ReportLabels labels={targetedCountries} labelType="country" />
            <ReportLabels labels={targetedIndustries} labelType="industry" />
            <ReportLabels labels={adversaries} labelType="adversary" />
            <ReportLabels labels={malwares} labelType="malware" />
            <ReportLabels labels={capability} labelType="capability" />
          </>
        ) : (
          <Box />
        )}
      </Stack>
    </Stack>
  )
}
