import { Stack, Typography, useTheme } from '@mui/material'
import { Dialog } from 'components/Dialog/Dialog'
import { TextField } from 'components/TextField/TextField'
import { useFormik } from 'formik'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { challengeAccountMFA } from 'store/slices/account'
import * as Yup from 'yup'

interface IOtpDialogProps {
  open: boolean
  handleClose: () => void
  handleBack: () => void
  isUpdate?: boolean
}

export const OtpDialog = ({
  open,
  handleClose,
  handleBack,
  isUpdate,
}: IOtpDialogProps) => {
  const { t } = useTranslation(['mfa', 'component'])

  const theme = useTheme()
  const dispatch = useAppDispatch()

  const { touched, errors, values, handleSubmit, handleChange, setFieldError } =
    useFormik({
      initialValues: { code: '' },
      validationSchema: Yup.object().shape({
        code: Yup.string().required(
          t('fieldErrorMessage.required', { ns: 'component' })
        ),
      }),
      onSubmit: () => {
        dispatch(
          challengeAccountMFA({
            code: values.code,
            successCallback: () => {
              handleClose()
            },
            failCallback: () => {
              setFieldError(
                'code',
                t('otpDialog.inputErrorMessage', { ns: 'mfa' })
              )
            },
          })
        )
      },
    })

  const dialogTitle = isUpdate
    ? t('mfa.drawer.updateDialog.title', { ns: 'account' })
    : t('otpDialog.title', { ns: 'mfa' })

  return (
    <Dialog
      open={open}
      handleDialogClose={handleClose}
      handleDialogBack={handleBack}
      title={dialogTitle}
      content={
        <Stack sx={{ gap: 6 }}>
          <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
            {t('otpDialog.description', { ns: 'mfa' })}
          </Typography>
          <TextField
            name="code"
            label={t('otpDialog.inputLabel', { ns: 'mfa' })}
            type="text"
            value={values.code}
            onChange={handleChange}
            error={touched.code && !!errors.code}
            helperText={touched.code && errors.code}
            inputProps={{ maxLength: 6 }}
          />
        </Stack>
      }
      confirmButtonText={t('otpDialog.confirmCta', { ns: 'mfa' })}
      handleConfirmButtonClick={handleSubmit}
    />
  )
}
