import { Stack, Typography, useTheme } from '@mui/material'
import { TwoToneLabel } from 'components/Label/Label'
import { PageSection } from 'components/PageSection/PageSection'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useTranslation } from 'react-i18next'
import { selectIsFetchAccountSettingsLoading } from 'store/slices/account'
import { IAccountSettings } from 'store/types/entityTypes/account'
import { TOtherServicesSubscriberGroupsKey } from 'store/types/entityTypes/tlpTarget'

import { DDWRiskMonitoringLoading } from './DDWRiskMonitoringLoading'

export const DDWRiskMonitoring = ({
  otherServicesSubscriberGroups,
}: IAccountSettings) => {
  const { t } = useTranslation(['account'])
  const theme = useTheme()
  const { formatDuration } = useDateTime()
  const accountSettingsLoading = useAppSelector(
    selectIsFetchAccountSettingsLoading
  )

  const otherServicesSubscriberGroupDisplay: Record<
    TOtherServicesSubscriberGroupsKey,
    string
  > = {
    'deep_dark_web_monitor': t('ddw.ddwAlert', { ns: 'account' }),
    'deep_dark_web_tailored_report': t('ddw.ddwTailoredReport', {
      ns: 'account',
    }),
  }

  return (
    <PageSection title={t('ddw.ddwRiskMonitoring', { ns: 'account' })}>
      {accountSettingsLoading ? (
        <DDWRiskMonitoringLoading />
      ) : (
        <Stack sx={{ gap: 1, px: 4, py: 2 }}>
          {!otherServicesSubscriberGroups ||
          otherServicesSubscriberGroups.length === 0 ? (
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE_60 }}
            >
              {t('ddw.emptyState', { ns: 'account' })}
            </Typography>
          ) : (
            otherServicesSubscriberGroups.map(
              (otherServicesSubscriberGroup) => (
                <TwoToneLabel
                  key={otherServicesSubscriberGroup.key}
                  keyText={
                    otherServicesSubscriberGroupDisplay[
                      otherServicesSubscriberGroup.key
                    ]
                  }
                  valueText={formatDuration(
                    otherServicesSubscriberGroup.joinedAt,
                    otherServicesSubscriberGroup.expiresAt
                  )}
                  mainColor={theme.colors.WIPER}
                  textColor={theme.colors.BLACK_90}
                />
              )
            )
          )}
        </Stack>
      )}
    </PageSection>
  )
}
