import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { ReactComponent as DDWAlertIcon } from 'assets/ddw/DDW-alert-icon.svg'
import BackgroundImage from 'assets/ddw/risk-monitoring-background-image.jpg'
import { Button, LinkButton } from 'components/Button/Button'
import { PAGE_TITLE_RISK_MONITORING } from 'constants/pageTitle'
import { DDW_ALERT_DEMO_ROUTE } from 'constants/routeParams'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { openMail } from 'utils/mail'

export const DDWAlertEntry = () => {
  const theme = useTheme()
  const { t } = useTranslation(['ddw', 'component'])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_RISK_MONITORING}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          background: `url(${BackgroundImage}) lightgray 50% / cover no-repeat`,
        }}
      >
        <Stack sx={{ px: 16, py: 12, color: theme.colors.WHITE, gap: 12 }}>
          <Stack>
            <Typography variant="titleBig">
              {t('entry.ddwRiskMonitoring.title', { ns: 'ddw' })}
            </Typography>
            <Typography variant="bodySmall" sx={{ maxWidth: '45rem', mt: 2 }}>
              <Trans t={t} i18nKey="entry.ddwRiskMonitoring.description">
                Assist clients in monitoring whether leaked credentials on the
                Deep and Dark Web have been used to commit crimes, and
                strengthens monitoring of social media platforms where
                cybercrime is rampant in China.
                <br />
                By notifying monitoring results in a timely manner, enterprise
                organizations can take contingency measures as early as possible
                to effectively reduce the risk of losses caused by credential
                leakage.
              </Trans>
            </Typography>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={openMail}
              sx={{ width: '11.5rem', mt: 6 }}
            >
              {t('contactCta', { ns: 'component' })}
            </Button>
          </Stack>
          <Box sx={{ display: 'flex', gap: 12 }}>
            <Stack sx={{ gap: 2, flex: '1 1 0%' }}>
              <SvgIcon
                sx={{ width: '5rem', height: '5rem' }}
                component={DDWAlertIcon}
                inheritViewBox
              />
              <Stack sx={{ gap: 6 }}>
                <Stack sx={{ gap: 2 }}>
                  <Typography variant="title">
                    {t('entry.ddwAlert.title', { ns: 'ddw' })}
                  </Typography>
                  <Typography variant="bodySmall">
                    <Trans t={t} i18nKey="entry.ddwAlert.description">
                      ThreatVision provides automatic scanning of Deep and Dark
                      Web Risk Monitoring once per week.
                      <br />
                      The monitoring scope covers secret-stealing malware,
                      leaked servers, etc. The relevant monitoring results will
                      be automatically updated on the platform. Email alert
                      notifications will also be sent via email.
                      <br />
                      The platform provides querying of historical monitoring
                      results to assist customers in effective internal risk
                      management and disposal. The historical data can also be
                      downloaded in CSV format.
                    </Trans>
                  </Typography>
                </Stack>
                <LinkButton
                  to={`/${DDW_ALERT_DEMO_ROUTE}`}
                  endIcon={
                    <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                  }
                  sx={{ width: '100%' }}
                >
                  {t('entry.ddwAlert.demoCta', { ns: 'ddw' })}
                </LinkButton>
              </Stack>
            </Stack>
            <Stack sx={{ flex: '1 1 0%' }} />
          </Box>
        </Stack>
      </Box>
    </>
  )
}
