import { TCommentType } from './comment'

export type TMalwareAttribution = string | 'shared' | 'opensource' | 'unknown'

export type TMalwareType =
  | 'RAT'
  | 'Dropper'
  | 'Loader'
  | 'Downloader'
  | 'Hacking Tool'
  | 'Webshell'
  | 'Ransomware'
  | 'Rootkit'
  | 'Wiper'

export interface IMalware {
  id: string
  name: string
  aliases: string[]
  firstSeen: number
  attribution: TMalwareAttribution
  hasTools: boolean
  overview: string
  type: TMalwareType | '' | null // TODO: 之後會改成沒有 null
  commentableType: TCommentType
}

export interface IMalwareAPIData {
  main_value: string
  name: string
  aliases: string[]
  first_seen: number
  attribution: TMalwareAttribution
  has_tools: boolean
  overview: string
  type: TMalwareType | '' | null // TODO: 之後會改成沒有 null
  commentable_type: TCommentType
}

export const mapAPIMalwareToState = (
  fromAPIMalware: IMalwareAPIData
): IMalware => ({
  id: fromAPIMalware.main_value,
  name: fromAPIMalware.name,
  aliases: fromAPIMalware.aliases,
  attribution: fromAPIMalware.attribution,
  overview: fromAPIMalware.overview,
  type: fromAPIMalware.type,
  firstSeen: fromAPIMalware.first_seen,
  hasTools: fromAPIMalware.has_tools,
  commentableType: fromAPIMalware.commentable_type,
})

export const mapAPIMalwaresToState = (
  fromAPIMalwares: IMalwareAPIData[]
): IMalware[] => fromAPIMalwares.map(mapAPIMalwareToState)
