import { Box, Fade, Popover, SvgIcon, useTheme } from '@mui/material'
import { DDW_MONITOR_KEY, DDW_TAILORED_REPORT_KEY } from 'constants/ddw'
import {
  DDW_ALERT_ENTRY_ROUTE,
  DDW_ALERT_ROUTE,
  DDW_TAILORED_REPORTS_ENTRY_ROUTE,
  DDW_TAILORED_REPORTS_ROUTE,
  RISK_MONITORING_ROUTE,
} from 'constants/routeParams'
import { useAppSelector } from 'hooks/useReduxHooks'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'
import { selectAccountSettings } from 'store/slices/account'
import { hideScrollBarStyles } from 'theme/scrollbar'

import { ReactComponent as DDWIcon } from './assets/ddw.svg'
import { NavbarOptionAccordion } from './NavbarOptionAccordion'
import {
  LogoButton,
  NavbarOptionButton,
  useNavbarSubOptions,
} from './NavbarOptions'

export const Navbar = () => {
  const { t } = useTranslation(['navbar'])
  const theme = useTheme()
  const { pathname } = useLocation()
  const navbarSubOptions = useNavbarSubOptions()
  const filterShowNavbarSubOptions = navbarSubOptions.filter(
    ({ hide }) => !hide
  )
  const accountSettings = useAppSelector(selectAccountSettings)
  const isSubscribeDdwMonitor =
    accountSettings.otherServicesSubscriberGroups &&
    accountSettings.otherServicesSubscriberGroups.length > 0
  const isSubscribeDdwAlert =
    accountSettings.otherServicesSubscriberGroups?.find(
      (otherServicesSubscriberGroup) =>
        otherServicesSubscriberGroup.key === DDW_MONITOR_KEY
    )
  const isSubscribeDDWTailoredReport =
    accountSettings.otherServicesSubscriberGroups?.find(
      (otherServicesSubscriberGroup) =>
        otherServicesSubscriberGroup.key === DDW_TAILORED_REPORT_KEY
    )

  const [navbarExpand, setNavbarExpand] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [accordionExpand, setAccordionExpand] = useState(false)

  const handleAccordionExpand = (expand: boolean) => {
    setAccordionExpand(expand)
  }

  const handleMouseEnter = (event: MouseEvent<HTMLElement>) => {
    setNavbarExpand(true)
    setAnchorEl(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setNavbarExpand(false)
    setAnchorEl(null)
  }

  const canBeOpen = navbarExpand && Boolean(anchorEl)
  const id = canBeOpen ? 'transition-popper' : undefined

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: theme.colors.BLACK_90,
        width: '2rem',
        height: '100%',
      }}
    >
      <LogoButton />
      <Box
        data-testid="navbar-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'scroll',
          overflowX: 'hidden',
          flexGrow: 1,
          ...hideScrollBarStyles,
        }}
        onMouseEnter={handleMouseEnter}
      >
        <Box>
          {filterShowNavbarSubOptions.map(
            ({ activeKey, callback, linkTo, icon, expandedText }) => (
              <NavbarOptionButton
                isActive={Boolean(
                  activeKey &&
                    matchPath({ path: activeKey, end: false }, pathname)
                )}
                key={expandedText}
                callback={callback}
                linkTo={linkTo}
                icon={icon}
              />
            )
          )}
          <NavbarOptionButton
            isActive={Boolean(
              matchPath(
                { path: `/${DDW_ALERT_ROUTE}`, end: false },
                pathname
              ) ||
                matchPath(
                  { path: `/${RISK_MONITORING_ROUTE}`, end: false },
                  pathname
                ) ||
                matchPath(
                  { path: `/${DDW_TAILORED_REPORTS_ROUTE}`, end: false },
                  pathname
                )
            )}
            icon={
              <SvgIcon
                sx={{ width: '1.5rem', height: '1.5rem' }}
                component={DDWIcon}
                inheritViewBox
              />
            }
          />
        </Box>
      </Box>
      <Popover
        data-testid="navbar-popover"
        id={id}
        open={navbarExpand}
        onClose={handleMouseLeave}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        TransitionComponent={Fade}
        marginThreshold={0}
        PaperProps={{ square: true }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: `calc(100vh - ${theme.fixedConstants.FIXED_TOP_HEIGHT})`,
            minWidth: '17rem',
            bgcolor: theme.colors.BLACK_90,
            overflowY: 'scroll',
            overflowX: 'hidden',
            ...hideScrollBarStyles,
          }}
          onMouseLeave={handleMouseLeave}
        >
          <Box>
            {filterShowNavbarSubOptions.map(
              ({ activeKey, callback, linkTo, icon, expandedText }) => (
                <NavbarOptionButton
                  key={expandedText}
                  callback={callback}
                  linkTo={linkTo}
                  icon={icon}
                  expandedText={expandedText}
                  isActive={Boolean(
                    activeKey &&
                      matchPath({ path: activeKey, end: false }, pathname)
                  )}
                />
              )
            )}
            {isSubscribeDdwMonitor ? (
              <NavbarOptionAccordion
                icon={
                  <SvgIcon
                    sx={{ width: '1.5rem', height: '1.5rem' }}
                    component={DDWIcon}
                    inheritViewBox
                  />
                }
                isActive={Boolean(
                  matchPath(
                    { path: `/${DDW_ALERT_ROUTE}`, end: false },
                    pathname
                  ) ||
                    matchPath(
                      { path: `/${RISK_MONITORING_ROUTE}`, end: false },
                      pathname
                    ) ||
                    matchPath(
                      { path: `/${DDW_TAILORED_REPORTS_ROUTE}`, end: false },
                      pathname
                    )
                )}
                text={t('riskMonitoring', { ns: 'navbar' })}
                expand={accordionExpand}
                handleAccordionExpand={handleAccordionExpand}
              >
                <>
                  <NavbarOptionButton
                    linkTo={
                      isSubscribeDdwAlert
                        ? `/${DDW_ALERT_ROUTE}`
                        : `/${DDW_ALERT_ENTRY_ROUTE}`
                    }
                    expandedText={t('ddwAlert', { ns: 'navbar' })}
                    isActive={Boolean(
                      matchPath(
                        { path: `/${DDW_ALERT_ROUTE}`, end: false },
                        pathname
                      )
                    )}
                  />
                  <NavbarOptionButton
                    linkTo={
                      isSubscribeDDWTailoredReport
                        ? `/${DDW_TAILORED_REPORTS_ROUTE}`
                        : `/${DDW_TAILORED_REPORTS_ENTRY_ROUTE}`
                    }
                    expandedText={t('ddwTailoredReports', { ns: 'navbar' })}
                    isActive={Boolean(
                      matchPath(
                        {
                          path: `/${DDW_TAILORED_REPORTS_ROUTE}`,
                          end: false,
                        },
                        pathname
                      )
                    )}
                  />
                </>
              </NavbarOptionAccordion>
            ) : (
              <NavbarOptionButton
                icon={
                  <SvgIcon
                    sx={{ width: '1.5rem', height: '1.5rem' }}
                    component={DDWIcon}
                    inheritViewBox
                  />
                }
                linkTo={`/${RISK_MONITORING_ROUTE}`}
                expandedText={t('riskMonitoring', { ns: 'navbar' })}
                isActive={Boolean(
                  matchPath(
                    { path: `/${RISK_MONITORING_ROUTE}`, end: false },
                    pathname
                  )
                )}
              />
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
