import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import * as Sentry from '@sentry/react'
import { ReactComponent as ArrowBackwardIcon } from 'assets/basicIcons/arrowBackward.svg'
import { getChildrenRoutes } from 'ChildrenRoutes'
import { AccountDropdown } from 'components/AccountDropdown/AccountDropdown'
import { Footer } from 'components/Footer/Footer'
import { InfoDialog } from 'components/MfaSetupDialog/InfoDialog'
import { KeyDialog } from 'components/MfaSetupDialog/KeyDialog'
import { OtpDialog } from 'components/MfaSetupDialog/OtpDialog'
import { Navbar } from 'components/Navbar/Navbar'
import { SearchBar } from 'components/SearchBar/SearchBar'
import { MFADialogStep } from 'constants/mfa'
import { SearchParamKey } from 'constants/searchParamKeys'
import { GobackContext } from 'context/GobackContext'
import { WindowSizeContext } from 'context/WindowSizeContext'
import ErrorBoundary from 'ErrorBoundary'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { IWindowSize, useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import {
  fetchAccountMFASettings,
  fetchAccountMFASettingsCancelled,
  fetchAccountSettings,
  fetchAccountSettingsCancelled,
  selectAccountMFASettings,
  selectAccountSettings,
  selectLocale,
} from 'store/slices/account'
import {
  logout,
  selectAccessToken,
  selectAfterLogin,
  selectExpireTime,
} from 'store/slices/auth'
import { IAccountSettings } from 'store/types/entityTypes/account'
import { hideScrollBarStyles } from 'theme/scrollbar'

export const App = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { i18n, t } = useTranslation(['component'])
  const navigate = useNavigate()
  const location = useLocation()
  const renderSearchBar = !useMatch('search')
  const windowSize: IWindowSize = useWindowSize()
  const [searchParams] = useSearchParams()
  const isAfterLogin = useAppSelector(selectAfterLogin)

  const debugMode = Boolean(searchParams.get(SearchParamKey.DEBUG_FLAG))

  const topOffset = theme.fixedConstants.FIXED_TOP_HEIGHT
  const bottomOffset = theme.fixedConstants.FIXED_FOOTER_HEIGHT
  const navbarOffset = theme.fixedConstants.FIXED_NAVBAR_WIDTH

  const [backTo, setBackTo] = useState<boolean>(false)
  const [mfaDialogStep, setMfaDialogStep] = useState<MFADialogStep | null>(null)

  const goBackContextValue = useMemo(() => ({ backTo, setBackTo }), [backTo])
  const handleGobackClick = () => navigate(-1)

  const expireTime = useAppSelector(selectExpireTime)
  const accessToken = useAppSelector(selectAccessToken)
  const accountSettings: IAccountSettings = useAppSelector(
    selectAccountSettings
  )
  const accountMFASettings = useAppSelector(selectAccountMFASettings)

  const locale = useAppSelector(selectLocale)

  const handleMfaDialogStep = (step: MFADialogStep | null) => {
    setMfaDialogStep(step)
  }

  useEffect(() => {
    if (!accessToken) {
      navigate('/login')
    }

    // 檢查 token 是否過期
    const isExpired =
      accessToken && !!expireTime && new Date().getTime() > expireTime
    if (isExpired) {
      dispatch(
        logout({
          reason: 'expired',
          pathBeforeLogout: location.pathname + location.search,
        })
      )
    }
  }, [accessToken])

  useEffect(() => {
    dispatch(fetchAccountSettings())
    dispatch(fetchAccountMFASettings())

    return () => {
      dispatch(fetchAccountSettingsCancelled())
      dispatch(fetchAccountMFASettingsCancelled())
    }
  }, [])

  useEffect(() => {
    // 確保有 user 資料後，再設定 i18n
    if (accountSettings.email) {
      if (locale === 'en') {
        i18n.changeLanguage('en')
      } else if (locale === 'zh-TW') {
        i18n.changeLanguage('zh')
      } else if (locale === 'ja') {
        i18n.changeLanguage('jp')
      } else if (locale === null) {
        if (navigator.language.includes('en')) {
          i18n.changeLanguage('en')
        } else if (navigator.language.includes('ja')) {
          i18n.changeLanguage('jp')
        } else if (navigator.language.includes('zh')) {
          i18n.changeLanguage('zh')
        }
      }
    }
  }, [accountSettings.email, locale])

  useEffect(() => {
    if (accountSettings.email) {
      Sentry.setUser({ username: accountSettings.email })
      Sentry.captureMessage('user landing')
    }
  }, [accountSettings.email])

  const showMFADialog =
    accountSettings &&
    accountSettings.mfaPopup &&
    accountMFASettings &&
    accountMFASettings.mfaEnable === null

  useEffect(() => {
    if (showMFADialog && isAfterLogin) {
      setMfaDialogStep(MFADialogStep.INFO)
    }
  }, [accountSettings.mfaPopup, accountMFASettings?.mfaEnable])

  return (
    <WindowSizeContext.Provider value={windowSize}>
      <GobackContext.Provider value={goBackContextValue}>
        <Helmet>
          <title>ThreatVision</title>
        </Helmet>
        <Box
          data-testid="app-container"
          sx={{
            width: '100vw',
            height: '100vh',
            overflowY: 'hidden',
            overflowX: 'auto',
            display: 'flex',
            ...hideScrollBarStyles,
          }}
        >
          <Box sx={{ flexShrink: 0 }}>
            <Navbar />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              bgcolor: theme.colors.BLACK_85,
            }}
          >
            {renderSearchBar ? (
              <Box
                sx={{
                  height: theme.fixedConstants.FIXED_TOP_HEIGHT,
                  display: 'flex',
                }}
              >
                {backTo && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexShrink: 0,
                      alignItems: 'center',
                      color: theme.colors.WHITE_60,
                      pl: 3,
                      pr: 4,
                      py: 1,
                      cursor: 'pointer',
                      gap: 0.5,
                      border: `1px solid ${theme.colors.WHITE_20}`,
                      borderLeftWidth: 0,
                      borderTopWidth: 0,
                      bgcolor: theme.colors.BLACK_90,
                    }}
                    onClick={handleGobackClick}
                  >
                    <SvgIcon
                      sx={{ width: '1.5rem', height: '1.5rem' }}
                      component={ArrowBackwardIcon}
                      inheritViewBox
                    />
                    <Typography variant="buttonLText">
                      {t('backCta', { ns: 'component' })}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    bgcolor: theme.colors.BLACK_90,
                    width: '100%',
                  }}
                >
                  <SearchBar />
                  <Box
                    sx={{
                      px: 2,
                      borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                    }}
                  >
                    <AccountDropdown {...accountSettings} />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ pr: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <AccountDropdown backgroundColor="none" {...accountSettings} />
              </Box>
            )}
            <Box
              sx={{
                height: `calc(100vh - ${topOffset} - ${bottomOffset})`,
                width: `calc(100vw - ${navbarOffset})`,
                minWidth: `calc(1280px - ${navbarOffset})`,
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  [theme.breakpoints.up('xl')]: {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                }}
              >
                <ErrorBoundary debugMode={debugMode}>
                  {getChildrenRoutes()}
                </ErrorBoundary>
              </Box>
            </Box>
            <Box
              sx={{
                height: theme.fixedConstants.FIXED_FOOTER_HEIGHT,
                zIndex: theme.zIndex.appBar,
              }}
            >
              <Footer />
            </Box>
          </Box>
          {showMFADialog && mfaDialogStep === MFADialogStep.INFO && (
            <InfoDialog
              open={mfaDialogStep === MFADialogStep.INFO}
              handleClose={() => handleMfaDialogStep(null)}
              handleConfirm={() => handleMfaDialogStep(MFADialogStep.KEY)}
            />
          )}
          {showMFADialog && mfaDialogStep === MFADialogStep.KEY && (
            <KeyDialog
              open={mfaDialogStep === MFADialogStep.KEY}
              handleClose={() => handleMfaDialogStep(null)}
              handleConfirm={() => handleMfaDialogStep(MFADialogStep.OTP)}
            />
          )}
          {showMFADialog && mfaDialogStep === MFADialogStep.OTP && (
            <OtpDialog
              open={mfaDialogStep === MFADialogStep.OTP}
              handleClose={() => handleMfaDialogStep(null)}
              handleBack={() => handleMfaDialogStep(MFADialogStep.KEY)}
            />
          )}
        </Box>
      </GobackContext.Provider>
    </WindowSizeContext.Provider>
  )
}
