import type { Action } from '@reduxjs/toolkit'
import {
  CREATE_ACCOUNT_API_KEYS,
  DELETE_ACCOUNT_API_KEYS,
  DISABLE_MFA,
  ENABLE_MFA,
  FETCH_ACCOUNT_API_KEYS,
  FETCH_ACCOUNT_COMPLETE_SHARE_TARGETS,
  FETCH_ACCOUNT_FIND_SHARE_TARGET,
  FETCH_ACCOUNT_MFA_SETTINGS,
  FETCH_ACCOUNT_ON_DEMAND_REPORTS,
  FETCH_ACCOUNT_SETTINGS,
  FETCH_ACCOUNT_SETTINGS_DEFAULT_SHARE,
  FETCH_ACCOUNT_SETTINGS_OPTIONS,
  GET_MSSP_CHECK,
  getAccountGroupById,
  MFA_CHALLENGE,
  MFA_CREATE,
  MFA_IGNORE_POPUP,
  REMOVE_MFA,
  REPLACE_ACCOUNT_API_KEYS,
  UPDATE_ACCOUNT_PASSWORD,
  UPDATE_ACCOUNT_SETTINGS,
} from 'constants/api'
import i18n from 'i18next'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  EMPTY,
  filter,
  iif,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  challengeAccountMFA,
  challengeAccountMFAFulfilled,
  challengeAccountMFARejected,
  checkIsMssp,
  checkIsMsspFulfilled,
  checkIsMsspRejected,
  createAccountAPIKeys,
  createAccountAPIKeysFulfilled,
  createAccountAPIKeysRejected,
  createAccountMFA,
  createAccountMFAFulfilled,
  createAccountMFARejected,
  deleteAccountAPIKeys,
  deleteAccountAPIKeysFulfilled,
  deleteAccountAPIKeysRejected,
  disabledMFA,
  disabledMFARejected,
  enableMFA,
  enableMFARejected,
  fetchAccountAPIKeys,
  fetchAccountAPIKeysFulfilled,
  fetchAccountAPIKeysRejected,
  fetchAccountCompleteShareTargets,
  fetchAccountCompleteShareTargetsCancelled,
  fetchAccountCompleteShareTargetsFulfilled,
  fetchAccountCompleteShareTargetsRejected,
  fetchAccountFindShareTarget,
  fetchAccountFindShareTargetFulfilled,
  fetchAccountFindShareTargetRejected,
  fetchAccountGroup,
  fetchAccountGroupFulfilled,
  fetchAccountGroupRejected,
  fetchAccountMFASettings,
  fetchAccountMFASettingsFulfilled,
  fetchAccountMFASettingsRejected,
  fetchAccountOnDemandReports,
  fetchAccountOnDemandReportsFulfilled,
  fetchAccountOnDemandReportsRejected,
  fetchAccountSettings,
  fetchAccountSettingsCancelled,
  fetchAccountSettingsDefaultShare,
  fetchAccountSettingsDefaultShareCancelled,
  fetchAccountSettingsDefaultShareFulfilled,
  fetchAccountSettingsDefaultShareRejected,
  fetchAccountSettingsFulfilled,
  fetchAccountSettingsOptions,
  fetchAccountSettingsOptionsCancelled,
  fetchAccountSettingsOptionsFulfilled,
  fetchAccountSettingsOptionsRejected,
  fetchAccountSettingsRejected,
  ignoreAccountMFAPopup,
  ignoreAccountMFAPopupFulfilled,
  ignoreAccountMFAPopupRejected,
  removeMFA,
  removeMFARejected,
  replaceAccountAPIKeys,
  replaceAccountAPIKeysFulfilled,
  replaceAccountAPIKeysRejected,
  updateAccountAvatar,
  updateAccountAvatarFulfilled,
  updateAccountAvatarRejected,
  updateAccountPassword,
  updateAccountPasswordFulfilled,
  updateAccountPasswordRejected,
  updateAccountSettings,
  updateAccountSettingsFulfilled,
  updateAccountSettingsRejected,
} from 'store/slices/account'
import {
  pushAlertSnackbar,
  pushInfoSnackbar,
  pushSuccessSnackbar,
} from 'store/slices/snackbar'
import {
  mapAPIAccountAPIKeysToState,
  mapAPIAccountMFACreateToState,
  mapAPIAccountMFASettingsToState,
  mapAPIAccountOnDemandReportsToState,
  mapAPIAccountSettingsOptionsToState,
  mapAPIAccountSettingsToState,
} from 'store/types/entityTypes/account'
import {
  mapAPIDefaultShareToState,
  mapAPIShareTargetsToState,
  mapAPIShareTargetToState,
} from 'store/types/entityTypes/tlpTarget'
import {
  IChallengeAccountMFAResponse,
  ICheckIsMsspResponse,
  IDisableMFAResponse,
  IEnableMFAResponse,
  IFetchAccountAPIKeysResponse,
  IFetchAccountGroupResponse,
  IFetchAccountMFACreateResponse,
  IFetchAccountMFASettingsResponse,
  IFetchAccountOnDemandReportsResponse,
  IFetchAccountSettingsOptionsResponse,
  IFetchAccountSettingsResponse,
  IRemoveMFAResponse,
  IUpdateAccountPasswordResponse,
} from 'store/types/slicesTypes/account'
import {
  IFetchCompleteShareTargetsResponse,
  IFetchDefaultShareResponse,
  IFetchFindShareTargetResponse,
} from 'store/types/slicesTypes/tlpTarget'
import { fetchData } from 'utils/fetch.utils'

import { checkUnauthorizedToken } from './auth'

i18n.loadNamespaces(['snackbar'])

const fetchAccountSettingsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountSettings.match),
    switchMap(() =>
      fetchData<IFetchAccountSettingsResponse>({
        url: FETCH_ACCOUNT_SETTINGS,
      }).pipe(
        map((response: AjaxResponse<IFetchAccountSettingsResponse>) =>
          fetchAccountSettingsFulfilled(
            mapAPIAccountSettingsToState(response.response.data)
          )
        ),
        takeUntil(action$.pipe(filter(fetchAccountSettingsCancelled.match))),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountSettingsRejected(error))
          )
        )
      )
    )
  )

const updateAccountSettingsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(updateAccountSettings.match),
    switchMap((action) =>
      fetchData({
        method: 'PATCH',
        url: UPDATE_ACCOUNT_SETTINGS,
        body: action.payload,
      }).pipe(
        mergeMap(() =>
          merge(
            of(updateAccountSettingsFulfilled()),
            of(fetchAccountSettingsDefaultShare()),
            iif(
              () => Boolean(action.payload.successMessage),
              of(
                pushSuccessSnackbar({
                  text: action.payload.successMessage || '',
                })
              ).pipe(
                tap(() => {
                  if (action.payload.successCallback) {
                    action.payload.successCallback()
                  }
                })
              ),
              EMPTY
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountSettings()),
            of(updateAccountSettingsRejected(error)),
            iif(
              () => Boolean(action.payload.failMessage),
              of(
                pushAlertSnackbar({
                  text: action.payload.failMessage || '',
                })
              ),
              EMPTY
            )
          )
        )
      )
    )
  )

const updateAccountAvatarEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(updateAccountAvatar.match),
    switchMap((action) =>
      fetchData({
        method: 'PATCH',
        url: UPDATE_ACCOUNT_SETTINGS,
        body: action.payload,
        isUploadFile: true,
      }).pipe(
        mergeMap(() =>
          merge(
            of(updateAccountAvatarFulfilled()),
            of(fetchAccountSettings()),
            of(
              pushSuccessSnackbar({
                text: i18n.t('avatar.updateSuccess', {
                  ns: 'snackbar',
                }),
              })
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(updateAccountAvatarRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('avatar.updateFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const fetchAccountSettingsOptionsEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountSettingsOptions.match),
    switchMap(() =>
      fetchData<IFetchAccountSettingsOptionsResponse>({
        url: FETCH_ACCOUNT_SETTINGS_OPTIONS,
      }).pipe(
        map((response: AjaxResponse<IFetchAccountSettingsOptionsResponse>) =>
          fetchAccountSettingsOptionsFulfilled(
            mapAPIAccountSettingsOptionsToState(response.response.data)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchAccountSettingsOptionsCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountSettingsOptionsRejected(error))
          )
        )
      )
    )
  )

const fetchAccountSettingsDefaultShareEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountSettingsDefaultShare.match),
    switchMap(() =>
      fetchData<IFetchDefaultShareResponse>({
        url: FETCH_ACCOUNT_SETTINGS_DEFAULT_SHARE,
      }).pipe(
        map((response: AjaxResponse<IFetchDefaultShareResponse>) =>
          fetchAccountSettingsDefaultShareFulfilled(
            mapAPIDefaultShareToState(response.response.data)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchAccountSettingsDefaultShareCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountSettingsDefaultShareRejected(error))
          )
        )
      )
    )
  )

const fetchAccountCompleteShareTargetsEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountCompleteShareTargets.match),
    switchMap((action) =>
      fetchData<IFetchCompleteShareTargetsResponse>({
        url: `${FETCH_ACCOUNT_COMPLETE_SHARE_TARGETS}?query=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchCompleteShareTargetsResponse>) =>
          fetchAccountCompleteShareTargetsFulfilled(
            mapAPIShareTargetsToState(response.response.data)
          )
        ),
        takeUntil(
          action$.pipe(filter(fetchAccountCompleteShareTargetsCancelled.match))
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountCompleteShareTargetsRejected(error))
          )
        )
      )
    )
  )

const fetchAccountFindShareTargetEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountFindShareTarget.match),
    switchMap((action) =>
      fetchData<IFetchFindShareTargetResponse>({
        url: `${FETCH_ACCOUNT_FIND_SHARE_TARGET}?name=${action.payload}`,
      }).pipe(
        map((response: AjaxResponse<IFetchFindShareTargetResponse>) =>
          fetchAccountFindShareTargetFulfilled(
            mapAPIShareTargetToState(response.response.data)
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountFindShareTargetRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('tlp.shareFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const fetchAccountAPIKeysEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountAPIKeys.match),
    switchMap(() =>
      fetchData<IFetchAccountAPIKeysResponse>({
        url: FETCH_ACCOUNT_API_KEYS,
      }).pipe(
        map((response: AjaxResponse<IFetchAccountAPIKeysResponse>) =>
          fetchAccountAPIKeysFulfilled(
            mapAPIAccountAPIKeysToState(response.response)
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountAPIKeysRejected(error))
          )
        )
      )
    )
  )

const createAccountAPIKeysEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(createAccountAPIKeys.match),
    switchMap(() =>
      fetchData({
        method: 'POST',
        url: CREATE_ACCOUNT_API_KEYS,
      }).pipe(
        mergeMap(() =>
          merge(
            of(createAccountAPIKeysFulfilled()),
            of(fetchAccountAPIKeys()),
            of(
              pushSuccessSnackbar({
                text: i18n.t('apiKeys.turnOnSuccess', { ns: 'snackbar' }),
              })
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(createAccountAPIKeysRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('apiKeys.turnOnFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const deleteAccountAPIKeysEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(deleteAccountAPIKeys.match),
    switchMap(() =>
      fetchData({
        method: 'DELETE',
        url: DELETE_ACCOUNT_API_KEYS,
      }).pipe(
        mergeMap(() =>
          merge(
            of(deleteAccountAPIKeysFulfilled()),
            of(
              pushSuccessSnackbar({
                text: i18n.t('apiKeys.turnOffSuccess', { ns: 'snackbar' }),
              })
            ),
            of(fetchAccountAPIKeys())
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(deleteAccountAPIKeysRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('apiKeys.turnOffFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const replaceAccountAPIKeysEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(replaceAccountAPIKeys.match),
    switchMap(() =>
      fetchData({
        method: 'POST',
        url: REPLACE_ACCOUNT_API_KEYS,
      }).pipe(
        mergeMap(() =>
          merge(
            of(replaceAccountAPIKeysFulfilled()),
            of(
              pushInfoSnackbar({
                text: i18n.t('apiKeys.revokeSuccess', {
                  ns: 'snackbar',
                }),
              })
            ),
            of(fetchAccountAPIKeys())
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(replaceAccountAPIKeysRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('apiKeys.revokeFail', {
                  ns: 'snackbar',
                }),
              })
            )
          )
        )
      )
    )
  )

const fetchAccountGroupEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountGroup.match),
    switchMap((action) =>
      fetchData<IFetchAccountGroupResponse>({
        url: getAccountGroupById(action.payload),
      }).pipe(
        map((response: AjaxResponse<IFetchAccountGroupResponse>) =>
          fetchAccountGroupFulfilled(response.response.emails)
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountGroupRejected(error))
          )
        )
      )
    )
  )

const updateAccountPasswordEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(updateAccountPassword.match),
    switchMap((action) =>
      fetchData({
        method: 'PATCH',
        url: UPDATE_ACCOUNT_PASSWORD,
        body: action.payload,
      }).pipe(
        mergeMap((response: AjaxResponse<IUpdateAccountPasswordResponse>) =>
          iif(
            () => response.response.success,
            merge(
              of(updateAccountPasswordFulfilled()).pipe(
                tap(() => action.payload.successCallback())
              ),
              of(
                pushInfoSnackbar({
                  text: i18n.t('changePassword.changeSuccess', {
                    ns: 'snackbar',
                  }),
                })
              )
            ),
            of(updateAccountPasswordRejected()).pipe(
              tap(() => action.payload.failCallback())
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(updateAccountPasswordRejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('changePassword.changeFail', {
                  ns: 'snackbar',
                }),
              })
            )
          )
        )
      )
    )
  )

const fetchAccountOnDemandReportsEpic: TAppEpic = (
  action$
): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountOnDemandReports.match),
    switchMap(() =>
      fetchData<IFetchAccountOnDemandReportsResponse>({
        url: FETCH_ACCOUNT_ON_DEMAND_REPORTS,
      }).pipe(
        map((response: AjaxResponse<IFetchAccountOnDemandReportsResponse>) =>
          fetchAccountOnDemandReportsFulfilled(
            mapAPIAccountOnDemandReportsToState(
              response.response.on_demand_reports
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountOnDemandReportsRejected(error))
          )
        )
      )
    )
  )

const checkIsMsspEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(checkIsMssp.match),
    switchMap(() =>
      fetchData<ICheckIsMsspResponse>({
        url: GET_MSSP_CHECK,
      }).pipe(
        map((response: AjaxResponse<ICheckIsMsspResponse>) =>
          checkIsMsspFulfilled(response.response.mssp)
        ),
        catchError((error) =>
          concat(checkUnauthorizedToken(error), of(checkIsMsspRejected(error)))
        )
      )
    )
  )

const fetchAccountMFASettingsEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(fetchAccountMFASettings.match),
    switchMap(() =>
      fetchData<IFetchAccountMFASettingsResponse>({
        url: FETCH_ACCOUNT_MFA_SETTINGS,
      }).pipe(
        map((response: AjaxResponse<IFetchAccountMFASettingsResponse>) =>
          fetchAccountMFASettingsFulfilled(
            mapAPIAccountMFASettingsToState(response.response.data)
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(fetchAccountMFASettingsRejected(error))
          )
        )
      )
    )
  )

const enableMFAEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(enableMFA.match),
    switchMap(() =>
      fetchData<IEnableMFAResponse>({
        url: ENABLE_MFA,
        method: 'PATCH',
      }).pipe(
        mergeMap(() =>
          concat(
            of(fetchAccountMFASettings()),
            of(
              pushSuccessSnackbar({
                text: i18n.t('mfa.updateSuccess', {
                  ns: 'snackbar',
                }),
              })
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(enableMFARejected()),
            of(
              pushAlertSnackbar({
                text: i18n.t('mfa.updateFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const disableMFAEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(disabledMFA.match),
    switchMap(() =>
      fetchData<IDisableMFAResponse>({
        url: DISABLE_MFA,
        method: 'PATCH',
      }).pipe(
        mergeMap(() =>
          concat(
            of(fetchAccountMFASettings()),
            of(
              pushSuccessSnackbar({
                text: i18n.t('mfa.updateSuccess', {
                  ns: 'snackbar',
                }),
              })
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(disabledMFARejected()),
            of(
              pushAlertSnackbar({
                text: i18n.t('mfa.updateFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const removeMFAEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(removeMFA.match),
    switchMap((action) =>
      fetchData<IRemoveMFAResponse>({
        url: REMOVE_MFA,
        method: 'DELETE',
      }).pipe(
        mergeMap(() =>
          concat(
            of(fetchAccountMFASettings()),
            iif(
              () => action.payload, // isUpdate
              of(createAccountMFA()),
              of(
                pushSuccessSnackbar({
                  text: i18n.t('mfa.removeSuccess', {
                    ns: 'snackbar',
                  }),
                })
              )
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(removeMFARejected()),
            of(
              pushAlertSnackbar({
                text: i18n.t('mfa.removeFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

const ignoreAccountMFAPopupEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(ignoreAccountMFAPopup.match),
    switchMap(() =>
      fetchData({
        method: 'POST',
        url: MFA_IGNORE_POPUP,
      }).pipe(
        map(() => ignoreAccountMFAPopupFulfilled()),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(ignoreAccountMFAPopupRejected(error))
          )
        )
      )
    )
  )

const createAccountMFAEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(createAccountMFA.match),
    switchMap(() =>
      fetchData({
        method: 'POST',
        url: MFA_CREATE,
      }).pipe(
        map((response: AjaxResponse<IFetchAccountMFACreateResponse>) =>
          createAccountMFAFulfilled(
            mapAPIAccountMFACreateToState(response.response.data)
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(createAccountMFARejected(error))
          )
        )
      )
    )
  )

const challengeAccountMFAEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(challengeAccountMFA.match),
    switchMap((action) =>
      fetchData({
        method: 'POST',
        url: `${MFA_CHALLENGE}?mfa_token=${action.payload.code}`,
      }).pipe(
        mergeMap((response: AjaxResponse<IChallengeAccountMFAResponse>) =>
          iif(
            () => response.response.data.result,
            concat(
              of(challengeAccountMFAFulfilled()).pipe(
                tap(() => action.payload.successCallback())
              ),
              of(fetchAccountMFASettings()),
              of(
                pushSuccessSnackbar({
                  text: i18n.t('mfa.authSuccess', { ns: 'snackbar' }),
                })
              )
            ),
            of(challengeAccountMFARejected()).pipe(
              tap(() => action.payload.failCallback())
            )
          )
        ),
        catchError((error) =>
          concat(
            checkUnauthorizedToken(error),
            of(challengeAccountMFARejected(error)),
            of(
              pushAlertSnackbar({
                text: i18n.t('mfa.authFail', { ns: 'snackbar' }),
              })
            )
          )
        )
      )
    )
  )

export const accountEpic = combineEpics(
  fetchAccountSettingsEpic,
  updateAccountSettingsEpic,
  updateAccountAvatarEpic,
  fetchAccountSettingsOptionsEpic,
  fetchAccountSettingsDefaultShareEpic,
  fetchAccountCompleteShareTargetsEpic,
  fetchAccountFindShareTargetEpic,
  fetchAccountAPIKeysEpic,
  createAccountAPIKeysEpic,
  deleteAccountAPIKeysEpic,
  replaceAccountAPIKeysEpic,
  fetchAccountGroupEpic,
  updateAccountPasswordEpic,
  fetchAccountOnDemandReportsEpic,
  checkIsMsspEpic,
  fetchAccountMFASettingsEpic,
  enableMFAEpic,
  disableMFAEpic,
  removeMFAEpic,
  ignoreAccountMFAPopupEpic,
  createAccountMFAEpic,
  challengeAccountMFAEpic
)
