import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import {
  IAccountAPIKeys,
  IAccountMFACreate,
  IAccountMFASettings,
  IAccountOnDemandReports,
  IAccountSettings,
  IAccountSettingsOptions,
  TLanguages,
  TLocale,
} from 'store/types/entityTypes/account'
import { IDefaultShare, IShareTarget } from 'store/types/entityTypes/tlpTarget'
import {
  IAccountSlice,
  IChallengeAccountMFAPayload,
  IUpdateAccountPasswordPayload,
  IUpdateAccountSettingsPayload,
} from 'store/types/slicesTypes/account'

const initialAccountState: IAccountSlice = {
  accountSettings: {
    avatar: null,
    email: null,
    tlp: null,
    shareTargets: [],
    mainGroup: null,
    reportSubscriberGroups: null,
    iocSubscriberGroups: null,
    otherServicesSubscriberGroups: null,
    msspUser: false,
    automatedAnalysisPoint: {
      used: 0,
      total: 0,
      expiresAt: null,
    },
    requestForAssistanceHour: {
      used: 0,
      total: 0,
      expiresAt: null,
    },
    reportLanguages: [],
    locale: null,
    timezone: null,
    tzinfo: null,
    mfaPopup: false,
  },
  isFetchAccountSettingsLoading: false,
  updateAccountSettingsLoading: false,
  accountSettingsOptions: {
    reportLanguages: [],
    locales: [],
    timezones: [],
  },
  isFetchAccountSettingsOptionsLoading: true,
  accountSettingsDefaultShareLoading: false,
  accountSettingsDefaultShare: {
    tlp: 'amber',
    targets: [],
  },
  accountCompleteShareTargetsLoading: false,
  accountCompleteShareTargets: [],
  accountFindShareTargetLoading: false,
  accountFindShareTarget: {
    name: '',
    type: 'user',
    ofsId: '',
    canEdit: false,
  },
  accountAPIKeys: {
    enable: true,
    clientId: '',
    clientSecret: '',
  },
  accountGroup: null,
  isUpdateAccountPasswordLoading: false,
  isUpdateAccountAvatarLoading: false,
  accountOnDemandReports: [],
  isFetchAccountOnDemandReportsLoading: false,
  isSetFavorSiteLoading: false,
  isMssp: false,
  isCheckIsMsspLoading: true,
  accountMFASettings: null,
  isFetchAccountMFASettingsLoading: false,
  accountMFACreate: null,
  isCreateAccountMFALoading: false,
}

const accountSlice = createSlice({
  name: 'account',
  initialState: initialAccountState,
  reducers: {
    fetchAccountSettings(state) {
      state.isFetchAccountSettingsLoading = true
    },
    fetchAccountSettingsFulfilled(
      state,
      action: PayloadAction<IAccountSettings>
    ) {
      state.accountSettings = action.payload
      state.isFetchAccountSettingsLoading = false
    },
    fetchAccountSettingsRejected(state, _action: PayloadAction<AjaxError>) {
      state.accountSettings = initialAccountState.accountSettings
      state.isFetchAccountSettingsLoading = false
    },
    fetchAccountSettingsCancelled(state) {
      state.isFetchAccountSettingsLoading = false
    },
    updateAccountSettings(
      state,
      _action: PayloadAction<IUpdateAccountSettingsPayload>
    ) {
      state.updateAccountSettingsLoading = true
    },
    updateAccountSettingsFulfilled(state) {
      state.updateAccountSettingsLoading = false
    },
    updateAccountSettingsRejected(state, _action: PayloadAction<AjaxError>) {
      state.updateAccountSettingsLoading = false
    },
    updateReportLanguage(state, action: PayloadAction<TLanguages[]>) {
      state.accountSettings.reportLanguages = action.payload
    },
    updateSystemLocale(state, action: PayloadAction<TLocale>) {
      state.accountSettings.locale = action.payload
    },
    updateTimezone(state, action: PayloadAction<string | null>) {
      state.accountSettings.timezone = action.payload
    },
    removeAccountAvatar(state) {
      state.accountSettings.avatar = null
    },
    updateAccountAvatar(state, _action: PayloadAction<FormData>) {
      state.isUpdateAccountAvatarLoading = true
    },
    updateAccountAvatarFulfilled(state) {
      state.isUpdateAccountAvatarLoading = false
    },
    updateAccountAvatarRejected(state, _action: PayloadAction<AjaxError>) {
      state.isUpdateAccountAvatarLoading = false
    },
    fetchAccountSettingsOptions(state) {
      state.isFetchAccountSettingsOptionsLoading = true
    },
    fetchAccountSettingsOptionsFulfilled(
      state,
      action: PayloadAction<IAccountSettingsOptions>
    ) {
      state.accountSettingsOptions = action.payload
      state.isFetchAccountSettingsOptionsLoading = false
    },
    fetchAccountSettingsOptionsRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.accountSettingsOptions = initialAccountState.accountSettingsOptions
      state.isFetchAccountSettingsOptionsLoading = false
    },
    fetchAccountSettingsOptionsCancelled() {},
    fetchAccountSettingsDefaultShare(state) {
      state.accountSettingsDefaultShareLoading = true
    },
    fetchAccountSettingsDefaultShareFulfilled(
      state,
      action: PayloadAction<IDefaultShare>
    ) {
      state.accountSettingsDefaultShare = action.payload
      state.accountSettingsDefaultShareLoading = false
    },
    fetchAccountSettingsDefaultShareRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.accountSettingsDefaultShare =
        initialAccountState.accountSettingsDefaultShare
      state.accountSettingsDefaultShareLoading = false
    },
    fetchAccountSettingsDefaultShareCancelled(state) {
      state.accountSettingsDefaultShareLoading = false
    },
    fetchAccountCompleteShareTargets(state, _action: PayloadAction<string>) {
      state.accountCompleteShareTargetsLoading = true
    },
    fetchAccountCompleteShareTargetsFulfilled(
      state,
      action: PayloadAction<IShareTarget[]>
    ) {
      state.accountCompleteShareTargets = action.payload
      state.accountCompleteShareTargetsLoading = false
    },
    fetchAccountCompleteShareTargetsRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.accountCompleteShareTargets =
        initialAccountState.accountCompleteShareTargets
      state.accountCompleteShareTargetsLoading = false
    },
    fetchAccountCompleteShareTargetsCancelled(state) {
      state.accountCompleteShareTargetsLoading = false
    },
    resetAccountCompleteShareTargets(state) {
      state.accountCompleteShareTargets =
        initialAccountState.accountCompleteShareTargets
    },
    fetchAccountFindShareTarget(state, _action: PayloadAction<string>) {
      state.accountFindShareTargetLoading = true
    },
    fetchAccountFindShareTargetFulfilled(
      state,
      action: PayloadAction<IShareTarget>
    ) {
      state.accountFindShareTarget = action.payload
      state.accountFindShareTargetLoading = false
    },
    fetchAccountFindShareTargetRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.accountFindShareTarget = initialAccountState.accountFindShareTarget
      state.accountFindShareTargetLoading = false
    },
    resetFindShareTarget(state) {
      state.accountFindShareTarget = initialAccountState.accountFindShareTarget
      state.accountFindShareTargetLoading = false
    },
    fetchAccountAPIKeys() {},
    fetchAccountAPIKeysFulfilled(
      state,
      action: PayloadAction<IAccountAPIKeys>
    ) {
      state.accountAPIKeys = action.payload
    },
    fetchAccountAPIKeysCancelled() {},
    fetchAccountAPIKeysRejected(state, _action: PayloadAction<AjaxError>) {
      state.accountAPIKeys = initialAccountState.accountAPIKeys
    },
    createAccountAPIKeys() {},
    createAccountAPIKeysFulfilled() {},
    createAccountAPIKeysRejected(_state, _action: PayloadAction<AjaxError>) {},
    deleteAccountAPIKeys() {},
    deleteAccountAPIKeysFulfilled() {},
    deleteAccountAPIKeysRejected(_state, _action: PayloadAction<AjaxError>) {},
    replaceAccountAPIKeys() {},
    replaceAccountAPIKeysFulfilled() {},
    replaceAccountAPIKeysRejected(_state, _action: PayloadAction<AjaxError>) {},
    fetchAccountGroup(_state, _action: PayloadAction<number>) {},
    fetchAccountGroupFulfilled(state, action: PayloadAction<string[] | null>) {
      state.accountGroup = action.payload
    },
    fetchAccountGroupRejected(state, _action: PayloadAction<AjaxError>) {
      state.accountGroup = initialAccountState.accountGroup
    },
    updateAccountPassword(
      state,
      _action: PayloadAction<IUpdateAccountPasswordPayload>
    ) {
      state.isUpdateAccountPasswordLoading = true
    },
    updateAccountPasswordFulfilled(state) {
      state.isUpdateAccountPasswordLoading = false
    },
    updateAccountPasswordRejected(
      state,
      _action: PayloadAction<AjaxError | undefined>
    ) {
      state.isUpdateAccountPasswordLoading = false
    },
    fetchAccountOnDemandReports(state) {
      state.isFetchAccountOnDemandReportsLoading = true
    },
    fetchAccountOnDemandReportsFulfilled(
      state,
      action: PayloadAction<IAccountOnDemandReports[]>
    ) {
      state.accountOnDemandReports = action.payload
      state.isFetchAccountOnDemandReportsLoading = false
    },
    fetchAccountOnDemandReportsRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.accountOnDemandReports = initialAccountState.accountOnDemandReports
      state.isFetchAccountOnDemandReportsLoading = false
    },
    fetchAccountOnDemandReportsCancelled(state) {
      state.isFetchAccountOnDemandReportsLoading = false
    },
    checkIsMssp(state) {
      state.isCheckIsMsspLoading = true
    },
    checkIsMsspFulfilled(state, action: PayloadAction<boolean>) {
      state.isMssp = action.payload
      state.isCheckIsMsspLoading = false
    },
    checkIsMsspRejected(state, _action: PayloadAction<AjaxError>) {
      state.isMssp = initialAccountState.isMssp
      state.isCheckIsMsspLoading = false
    },
    checkIsMsspCancelled() {},
    fetchAccountMFASettings(state) {
      state.isFetchAccountMFASettingsLoading = true
    },
    fetchAccountMFASettingsFulfilled(
      state,
      action: PayloadAction<IAccountMFASettings>
    ) {
      state.accountMFASettings = action.payload
      state.isFetchAccountMFASettingsLoading = false
    },
    fetchAccountMFASettingsRejected(state, _action: PayloadAction<AjaxError>) {
      state.accountMFASettings = initialAccountState.accountMFASettings
      state.isFetchAccountMFASettingsLoading = false
    },
    fetchAccountMFASettingsCancelled(state) {
      state.isFetchAccountMFASettingsLoading = false
    },
    enableMFA() {},
    enableMFARejected() {},
    disabledMFA() {},
    disabledMFARejected() {},
    removeMFA(_state, _action: PayloadAction<boolean>) {}, // payload<boolean>: isUpdate
    removeMFARejected() {},
    ignoreAccountMFAPopup() {},
    ignoreAccountMFAPopupFulfilled() {},
    ignoreAccountMFAPopupRejected(_state, _action: PayloadAction<AjaxError>) {},
    createAccountMFA(state) {
      state.isCreateAccountMFALoading = true
    },
    createAccountMFAFulfilled(state, action: PayloadAction<IAccountMFACreate>) {
      state.accountMFACreate = action.payload
      state.isCreateAccountMFALoading = false
    },
    createAccountMFARejected(state, _action: PayloadAction<AjaxError>) {
      state.isCreateAccountMFALoading = false
    },
    createAccountMFACancelled(state) {
      state.isCreateAccountMFALoading = false
    },
    resetCreateAccountMFA(state) {
      state.accountMFACreate = initialAccountState.accountMFACreate
    },
    challengeAccountMFA(
      _state,
      _action: PayloadAction<IChallengeAccountMFAPayload>
    ) {},
    challengeAccountMFAFulfilled() {},
    challengeAccountMFARejected(
      _state,
      _action: PayloadAction<AjaxError | undefined>
    ) {},
  },
})

export const {
  fetchAccountSettings,
  fetchAccountSettingsFulfilled,
  fetchAccountSettingsRejected,
  fetchAccountSettingsCancelled,
  updateAccountSettings,
  updateAccountSettingsFulfilled,
  updateAccountSettingsRejected,
  removeAccountAvatar,
  updateAccountAvatar,
  updateAccountAvatarFulfilled,
  updateAccountAvatarRejected,
  updateReportLanguage,
  updateSystemLocale,
  updateTimezone,
  fetchAccountSettingsOptions,
  fetchAccountSettingsOptionsFulfilled,
  fetchAccountSettingsOptionsRejected,
  fetchAccountSettingsOptionsCancelled,
  fetchAccountSettingsDefaultShare,
  fetchAccountSettingsDefaultShareFulfilled,
  fetchAccountSettingsDefaultShareRejected,
  fetchAccountSettingsDefaultShareCancelled,
  fetchAccountCompleteShareTargets,
  fetchAccountCompleteShareTargetsFulfilled,
  fetchAccountCompleteShareTargetsRejected,
  fetchAccountCompleteShareTargetsCancelled,
  resetAccountCompleteShareTargets,
  fetchAccountFindShareTarget,
  fetchAccountFindShareTargetFulfilled,
  fetchAccountFindShareTargetRejected,
  resetFindShareTarget,
  fetchAccountAPIKeys,
  fetchAccountAPIKeysFulfilled,
  fetchAccountAPIKeysCancelled,
  fetchAccountAPIKeysRejected,
  createAccountAPIKeys,
  createAccountAPIKeysFulfilled,
  createAccountAPIKeysRejected,
  createAccountMFACancelled,
  deleteAccountAPIKeys,
  deleteAccountAPIKeysFulfilled,
  deleteAccountAPIKeysRejected,
  replaceAccountAPIKeys,
  replaceAccountAPIKeysFulfilled,
  replaceAccountAPIKeysRejected,
  fetchAccountGroup,
  fetchAccountGroupFulfilled,
  fetchAccountGroupRejected,
  updateAccountPassword,
  updateAccountPasswordFulfilled,
  updateAccountPasswordRejected,
  fetchAccountOnDemandReports,
  fetchAccountOnDemandReportsFulfilled,
  fetchAccountOnDemandReportsRejected,
  fetchAccountOnDemandReportsCancelled,
  checkIsMssp,
  checkIsMsspFulfilled,
  checkIsMsspRejected,
  checkIsMsspCancelled,
  fetchAccountMFASettings,
  fetchAccountMFASettingsFulfilled,
  fetchAccountMFASettingsRejected,
  fetchAccountMFASettingsCancelled,
  enableMFA,
  enableMFARejected,
  disabledMFA,
  disabledMFARejected,
  removeMFA,
  removeMFARejected,
  ignoreAccountMFAPopup,
  ignoreAccountMFAPopupFulfilled,
  ignoreAccountMFAPopupRejected,
  createAccountMFA,
  createAccountMFAFulfilled,
  createAccountMFARejected,
  resetCreateAccountMFA,
  challengeAccountMFA,
  challengeAccountMFAFulfilled,
  challengeAccountMFARejected,
} = accountSlice.actions

export const selectUpdateAccountSettingsLoading = (state: TRootState) =>
  state.account.updateAccountSettingsLoading
export const selectAccountSettings = (state: TRootState) =>
  state.account.accountSettings

export const selectIsFetchAccountSettingsLoading = (state: TRootState) =>
  state.account.isFetchAccountSettingsLoading

export const selectLocale = (state: TRootState) =>
  state.account.accountSettings.locale

export const selectAccountSettingsOptions = (state: TRootState) =>
  state.account.accountSettingsOptions

export const selectIsFetchAccountSettingsOptionsLoading = (state: TRootState) =>
  state.account.isFetchAccountSettingsOptionsLoading

export const selectAccountSettingsDefaultShareLoading = (state: TRootState) =>
  state.account.accountSettingsDefaultShareLoading
export const selectAccountSettingsDefaultShare = (state: TRootState) =>
  state.account.accountSettingsDefaultShare

export const selectAccountCompleteShareTargetsLoading = (state: TRootState) =>
  state.account.accountCompleteShareTargetsLoading
export const selectAccountCompleteShareTargets = (state: TRootState) =>
  state.account.accountCompleteShareTargets

export const selectAccountFindShareTargetLoading = (state: TRootState) =>
  state.account.accountFindShareTargetLoading
export const selectAccountFindShareTarget = (state: TRootState) =>
  state.account.accountFindShareTarget

export const selectAccountAPIKeys = (state: TRootState) =>
  state.account.accountAPIKeys

export const selectAccountGroup = (state: TRootState) =>
  state.account.accountGroup

export const selectUpdateAccountPasswordLoading = (state: TRootState) =>
  state.account.isUpdateAccountPasswordLoading

export const selectIsUpdateAccountAvatarLoading = (state: TRootState) =>
  state.account.isUpdateAccountAvatarLoading

export const selectAccountOnDemandReports = (state: TRootState) =>
  state.account.accountOnDemandReports

export const selectAccountOnDemandReportsLoading = (state: TRootState) =>
  state.account.isFetchAccountOnDemandReportsLoading

export const selectIsSetFavorSiteLoading = (state: TRootState) =>
  state.account.isSetFavorSiteLoading

export const selectAvailableRAH = (state: TRootState) =>
  state.account.accountSettings.requestForAssistanceHour.total -
  state.account.accountSettings.requestForAssistanceHour.used

export const selectIsMsspUser = (state: TRootState) => state.account.isMssp

export const selectIsCheckIsMsspLoading = (state: TRootState) =>
  state.account.isCheckIsMsspLoading

export const selectAccountMFASettings = (state: TRootState) =>
  state.account.accountMFASettings

export const selectIsAccountMFASettingsLoading = (state: TRootState) =>
  state.account.isFetchAccountMFASettingsLoading

export const selectAccountMFACreate = (state: TRootState) =>
  state.account.accountMFACreate

export const selectIsCreateAccountMFALoading = (state: TRootState) =>
  state.account.isCreateAccountMFALoading

export default accountSlice.reducer
