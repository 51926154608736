import { Box, Stack, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import { ReactComponent as TagIcon } from 'assets/basicIcons/tag.svg'
import { ReactComponent as TocIcon } from 'assets/report/icons/toc.svg'
import { Button } from 'components/Button/Button'
import { PurchaseToolButton } from 'components/Download/DownloadCta'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDownloadTool } from 'hooks/useDownloadTool'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { selectToolDownloadingIdList } from 'store/slices/download'
import {
  fetchReportIocContent,
  fetchReportPdfContent,
  selectIsFetchReportIocContentLoading,
  selectIsFetchReportPdfContentLoading,
  selectReport,
  selectReportFirstTool,
} from 'store/slices/report'
import { downloadPdfFile, downloadTextFile } from 'utils/download'

import {
  CYBER_AFFAIRS_TYPE,
  DDW_TAILORED_REPORT_TYPE,
  VIR_REPORT_TYPE,
} from '../constants/reportType'
import { ReportDetailLabels } from './ReportDetailLabels'
import { ReportDetailToc } from './ReportDetailToc'

const INFO_PADDING = '1.5rem'

const TAB_KEY_TAG = 'tag'
const TAB_KEY_TOC = 'toc'

interface IH2h3ListItem {
  tagName: string
  text: string
  anchorId: string
}

interface IReportDetailInfoProps {
  tocList: IH2h3ListItem[]
}

export const ReportDetailInfo = ({ tocList }: IReportDetailInfoProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['report'])
  const [searchParams] = useSearchParams()
  const alias = searchParams.get(SearchParamKey.REPORT_ALIAS)

  const dispatch = useAppDispatch()

  const isFetchReportPdfContentLoading = useAppSelector(
    selectIsFetchReportPdfContentLoading
  )
  const isFetchReportIocContentLoading = useAppSelector(
    selectIsFetchReportIocContentLoading
  )
  const report = useAppSelector(selectReport)
  const toolDownloadingIdList = useAppSelector(selectToolDownloadingIdList)
  const reportTool = useAppSelector(selectReportFirstTool)

  let tabs = [
    {
      key: TAB_KEY_TAG,
      name: t('reportDetailTabs.tag', { ns: 'report' }),
      icon: TagIcon,
    },
    {
      key: TAB_KEY_TOC,
      name: t('reportDetailTabs.toc', { ns: 'report' }),
      icon: TocIcon,
    },
  ]

  // DDW Tailored Report 只會有 toc，不會有 tag
  if (report && report.type === DDW_TAILORED_REPORT_TYPE) {
    tabs = tabs.slice(1)
  }

  const [selectedTab, setSelectedTab] = useState(tabs[0].key)

  const handleSelectTab = (tab: string) => {
    setSelectedTab(tab)
  }

  const showDownloadIoC =
    report?.type !== CYBER_AFFAIRS_TYPE &&
    report?.type !== DDW_TAILORED_REPORT_TYPE
  const showDownloadTool = report?.type === VIR_REPORT_TYPE

  const { handleDownloadTool } = useDownloadTool()

  const handleDownloadPdf = () => {
    if (alias) {
      dispatch(
        fetchReportPdfContent({
          alias,
          successCallback: ({ response, filename }) =>
            downloadPdfFile({ value: response, filename }),
        })
      )
    }
  }

  const handleDownloadIoC = () => {
    if (alias) {
      dispatch(
        fetchReportIocContent({
          alias,
          successCallback: (ioc) =>
            downloadTextFile({
              value: JSON.stringify(ioc),
              format: 'stix',
              filename: alias,
            }),
        })
      )
    }
  }

  return (
    <Box sx={{ px: 4, py: 6, height: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: '1.5rem',
          width: '21.875rem',
          height: `calc(100vh - ${theme.fixedConstants.FIXED_TOP_HEIGHT} - ${theme.fixedConstants.FIXED_FOOTER_HEIGHT} - ${INFO_PADDING} * 2)`,
        }}
      >
        <Stack sx={{ gap: 6, height: '100%' }}>
          <Box sx={{ px: 2, flexShrink: 0 }}>
            <Button
              endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
              sx={{ width: '100%' }}
              onClick={handleDownloadPdf}
              loading={isFetchReportPdfContentLoading}
              disabled={isFetchReportPdfContentLoading}
            >
              {t('downloadReportPdfCta', { ns: 'report' })}
            </Button>
            {showDownloadIoC && (
              <Button
                endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
                sx={{ width: '100%', mt: 2 }}
                onClick={handleDownloadIoC}
                disabled={!report?.stixUrl || isFetchReportIocContentLoading}
                loading={isFetchReportIocContentLoading}
              >
                {t('downloadReportIocCta', { ns: 'report' })}
              </Button>
            )}
            {showDownloadTool &&
              (reportTool && reportTool.purchasedInformation ? (
                <PurchaseToolButton
                  sx={{ width: '100%', mt: 2 }}
                  text={t('purchaseReportToolCta', {
                    ns: 'report',
                    count: reportTool.purchasedInformation.credit,
                  })}
                  {...reportTool}
                />
              ) : (
                <Button
                  endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
                  sx={{ width: '100%', mt: 2 }}
                  onClick={() => handleDownloadTool(reportTool)}
                  disabled={
                    !reportTool ||
                    toolDownloadingIdList.includes(reportTool?.id)
                  }
                  loading={toolDownloadingIdList.includes(reportTool?.id)}
                >
                  {t('downloadReportToolCta', { ns: 'report' })}
                </Button>
              ))}
          </Box>
          <Stack sx={{ flexGrow: 1, flexBasis: 0, height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                px: 2,
                py: 1.5,
                borderBottom: `2px solid ${theme.colors.PRIMARY}`,
                flexShrink: 0,
              }}
            >
              {tabs.map((tab) => (
                <TabWithCount
                  key={tab.key}
                  icon={tab.icon}
                  name={tab.name}
                  handleSelect={() => handleSelectTab(tab.key)}
                  showCount={false}
                  selected={selectedTab === tab.key}
                />
              ))}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                height: '100%',
              }}
            >
              {selectedTab === TAB_KEY_TAG && <ReportDetailLabels />}
              {selectedTab === TAB_KEY_TOC && (
                <ReportDetailToc tocList={tocList} />
              )}
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
