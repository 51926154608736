import {
  Box,
  CircularProgress,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as DeleteIcon } from 'assets/basicIcons/delete.svg'
import { Button } from 'components/Button/Button'
import { Drawer } from 'components/Drawer/Drawer'
import { KeyDialog } from 'components/MfaSetupDialog/KeyDialog'
import { OtpDialog } from 'components/MfaSetupDialog/OtpDialog'
import { RemoveMFADialog } from 'components/MfaSetupDialog/RemoveMFADialog'
import { UpdateMFAWarningDialog } from 'components/MfaSetupDialog/UpdateMFAWarningDialog'
import { Switch } from 'components/Switch/Switch'
import { MFADialogStep } from 'constants/mfa'
import { useDateTime } from 'hooks/useDatetime'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  disabledMFA,
  enableMFA,
  removeMFA,
  resetCreateAccountMFA,
  selectAccountMFASettings,
  selectIsAccountMFASettingsLoading,
} from 'store/slices/account'
import { pushAlertSnackbar } from 'store/slices/snackbar'

interface IMFADrawerProps {
  drawerOpen: boolean
  toggleDrawer: () => void
}

export const MFADrawer = ({ drawerOpen, toggleDrawer }: IMFADrawerProps) => {
  const { t } = useTranslation(['account', 'snackbar'])
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const dispatch = useAppDispatch()
  const accountMFASettings = useAppSelector(selectAccountMFASettings)
  const isAccountMFASettingsLoading = useAppSelector(
    selectIsAccountMFASettingsLoading
  )

  const MFAExist = accountMFASettings?.mfaEnable !== null

  const [updateMFADialogStep, setUpdateMFADialogStep] =
    useState<MFADialogStep | null>(null)

  const [removeWarningDialogOpen, setRemoveWarningDialogOpen] =
    useState<boolean>(false)

  const handleRemoveWarningDialogOpen = () => {
    setRemoveWarningDialogOpen(true)
  }

  const handleRemoveWarningDialogClose = () => {
    setRemoveWarningDialogOpen(false)
  }

  const handleChange = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      dispatch(enableMFA())
    } else {
      dispatch(disabledMFA())
    }
  }

  const handleRemoveMFA = () => {
    dispatch(removeMFA(false))
    dispatch(resetCreateAccountMFA())
    handleRemoveWarningDialogClose()
    toggleDrawer()
  }

  const handleUpdateDialogOpen = () => {
    setUpdateMFADialogStep(MFADialogStep.UPDATE_WARNING)
  }

  const handleUpdateDialogClose = () => {
    setUpdateMFADialogStep(null)
  }

  const handleKeyDialogClose = () => {
    dispatch(
      pushAlertSnackbar({
        text: t('mfa.updateFail', { ns: 'snackbar' }),
      })
    )
    handleUpdateDialogClose()
    toggleDrawer()
  }

  const handleWarningDialogConfirm = () => {
    dispatch(resetCreateAccountMFA()) // 清掉原先的 MFA Create Info 以便產生新的一組 Data
    dispatch(removeMFA(true)) // 移除 MFA 避免未設定完成，進入無法登入的迴圈
    setUpdateMFADialogStep(MFADialogStep.KEY)
  }

  const handleKeyDialogConfirm = () => {
    setUpdateMFADialogStep(MFADialogStep.OTP)
  }

  const handleOtpDialogGoBack = () => {
    setUpdateMFADialogStep(MFADialogStep.KEY)
  }

  if (!accountMFASettings) {
    return null
  }

  return (
    <Drawer open={drawerOpen} onClose={toggleDrawer}>
      <Stack sx={{ height: '100%', gap: 2 }}>
        <Typography
          variant="reportH1"
          color={theme.colors.WHITE}
          sx={{ borderBottom: `1px solid ${theme.colors.WHITE_20}` }}
        >
          {t('mfa.drawer.title', { ns: 'account' })}
        </Typography>
        <Stack sx={{ gap: 1 }}>
          <Typography variant="reportH3" color={theme.colors.PRIMARY}>
            {t('mfa.drawer.enable.title', { ns: 'account' })}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              sx={{ cursor: MFAExist ? 'pointer' : 'default' }}
              checked={Boolean(accountMFASettings.mfaEnable)}
              disabled={!MFAExist}
              onChange={handleChange}
            />
            {isAccountMFASettingsLoading ? (
              <CircularProgress />
            ) : (
              <Typography
                variant="body"
                color={MFAExist ? theme.colors.WHITE : theme.colors.DISABLE}
              >
                {accountMFASettings.mfaEnable
                  ? t('mfa.drawer.enable.enabled', { ns: 'account' })
                  : t('mfa.drawer.enable.disabled', { ns: 'account' })}
              </Typography>
            )}
          </Box>
        </Stack>
        <Stack sx={{ gap: 1 }}>
          <Typography variant="reportH3" color={theme.colors.PRIMARY}>
            {t('mfa.drawer.authenticator.title', { ns: 'account' })}
          </Typography>
          <Typography variant="body" color={theme.colors.WHITE}>
            {t('mfa.drawer.authenticator.description', { ns: 'account' })}
          </Typography>
          <Box
            sx={{
              p: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: `1px solid ${theme.colors.WHITE_20}`,
              borderRadius: 0.5,
            }}
          >
            <Stack sx={{ gap: 1 }}>
              <Typography
                variant="body"
                color={MFAExist ? theme.colors.WHITE : theme.colors.DISABLE}
              >
                {t('mfa.drawer.authenticator.authenticator', { ns: 'account' })}
              </Typography>
              {accountMFASettings.mfaEnabledAt && (
                <Typography
                  variant="body"
                  color={MFAExist ? theme.colors.WHITE : theme.colors.DISABLE}
                >
                  {t('mfa.drawer.authenticator.addedDate', {
                    ns: 'account',
                    date: getDateStringByTimestampsInSeconds(
                      Number(accountMFASettings.mfaEnabledAt)
                    ),
                  })}
                </Typography>
              )}
              <Box
                sx={{
                  height: '1.25rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="textLink"
                  color={
                    MFAExist
                      ? theme.colors.TEXT_LINK_NORMAL
                      : theme.colors.DISABLE
                  }
                  sx={{ cursor: MFAExist ? 'pointer' : 'default' }}
                  onClick={MFAExist ? handleUpdateDialogOpen : () => {}}
                >
                  {t('mfa.drawer.authenticator.updateLink', { ns: 'account' })}
                </Typography>
              </Box>
            </Stack>
            <Button
              variant="text"
              onClick={MFAExist ? handleRemoveWarningDialogOpen : () => {}}
              endIcon={
                <SvgIcon
                  component={DeleteIcon}
                  inheritViewBox
                  sx={{
                    path: {
                      fill: MFAExist
                        ? theme.colors.WHITE_60
                        : theme.colors.DISABLE,
                    },
                    width: '1rem',
                    height: '1rem',
                  }}
                />
              }
            >
              {t('mfa.drawer.authenticator.removeCta', { ns: 'account' })}
            </Button>
          </Box>
        </Stack>
      </Stack>
      <RemoveMFADialog
        open={removeWarningDialogOpen}
        handleConfirm={handleRemoveMFA}
        handleClose={handleRemoveWarningDialogClose}
      />
      {updateMFADialogStep === MFADialogStep.UPDATE_WARNING && (
        <UpdateMFAWarningDialog
          open={updateMFADialogStep === MFADialogStep.UPDATE_WARNING}
          handleClose={handleUpdateDialogClose}
          handleConfirm={handleWarningDialogConfirm}
        />
      )}
      {updateMFADialogStep === MFADialogStep.KEY && (
        <KeyDialog
          open={updateMFADialogStep === MFADialogStep.KEY}
          handleClose={handleKeyDialogClose}
          handleConfirm={handleKeyDialogConfirm}
          isUpdate
        />
      )}
      {updateMFADialogStep === MFADialogStep.OTP && (
        <OtpDialog
          open={updateMFADialogStep === MFADialogStep.OTP}
          handleClose={handleUpdateDialogClose}
          handleBack={handleOtpDialogGoBack}
          isUpdate
        />
      )}
    </Drawer>
  )
}
