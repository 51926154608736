import { Box, Stack, Typography, useTheme } from '@mui/material'
import { HighlightLabel, MalwareTypeLabel } from 'components/Label/Label'
import {
  MalwareAliasList,
  MalwareAttribution,
  MalwareThreatHuntingTools,
} from 'components/Malware/MalwareLabels'
import { MALWARE_CONTENT_ROUTE, MALWARES_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchMatchedMalware,
  fetchMatchedMalwareCancelled,
  selectMatchedCardLoading,
  selectMatchedMalware,
} from 'store/slices/search'

import { MatchedCardLayout } from './MatchedCardLayout'

interface IMalwareMatchedCardProps {
  malwareId: string
}

export const MalwareMatchedCard = ({ malwareId }: IMalwareMatchedCardProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const malware = useAppSelector(selectMatchedMalware)
  const matchedCardLoading = useAppSelector(selectMatchedCardLoading)
  const { t } = useTranslation(['search', 'component'])

  useEffect(() => {
    if (malwareId) {
      dispatch(fetchMatchedMalware(malwareId))
    }
    return () => {
      dispatch(fetchMatchedMalwareCancelled())
    }
  }, [malwareId])

  if (!malware || matchedCardLoading) {
    return null
  }

  const { id, name, type, aliases, attribution, hasTools, overview } = malware

  return (
    <MatchedCardLayout
      title={t('matchedCard.malware', { ns: 'search' })}
      linkText={t('readMoreCta', { ns: 'component' })}
      linkTo={`/${MALWARES_ROUTE}/${MALWARE_CONTENT_ROUTE}?${SearchParamKey.MALWARE_ID}=${id}`}
    >
      <Stack sx={{ p: 4, gap: 6 }}>
        <Box>
          <Typography sx={{ color: theme.colors.PRIMARY }} variant="title">
            {name}
          </Typography>
        </Box>
        <Stack sx={{ gap: 2 }}>
          {type && <MalwareTypeLabel type={type} />}
          <MalwareAliasList aliases={aliases} />
          <MalwareAttribution attribution={attribution} />
          <MalwareThreatHuntingTools id={id} hasTools={hasTools} />
          <Box>
            <HighlightLabel
              text={t('malwarePreview.labels.description', { ns: 'component' })}
            />
            <Box>
              <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
                {overview}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </MatchedCardLayout>
  )
}
