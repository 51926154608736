import {
  Box,
  Link,
  SvgIcon,
  SxProps,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import { MALWARE_CONTENT_ROUTE, MALWARES_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { MouseEvent } from 'react'
// import { useDateTime } from 'hooks/useDatetime'
import { IMalware } from 'store/types/entityTypes/malware'

import type { TFilterTextMap, TMalwareOrderKeys, TOrder } from './MalwareTable'
import {
  getDisplayAlias,
  getMalwareComparatorMap,
  useFilterMalware,
} from './malwareTable.config'

const threatHuntingToolsBaseStyles: SxProps = {
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: 0.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1, // 避免被 link 覆蓋而無法點擊 tool 按鈕
}

export const MalwareTableBodyCell = ({
  children,
  sx,
  ...otherProps
}: TableCellProps) => {
  const theme = useTheme()

  return (
    <TableCell
      sx={{
        height: '2.25rem',
        px: 4,
        py: 2,
        borderColor: theme.colors.WHITE_20,
        borderLeft: `1px solid ${theme.colors.WHITE_20}`,
        ...sx,
      }}
      {...otherProps}
    >
      <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
        {children}
      </Typography>
    </TableCell>
  )
}

interface IMalwareTableBodyProps {
  order: TOrder
  orderBy: TMalwareOrderKeys
  filterTextMap: TFilterTextMap
  malwares: IMalware[]
}

export const MalwareTableBody = ({
  order,
  orderBy,
  filterTextMap,
  malwares,
}: IMalwareTableBodyProps) => {
  const theme = useTheme()
  // const { getYearAndMonthStringByTimestampsInSeconds } = useDateTime()
  const filteredMalwares = useFilterMalware({ malwares, filterTextMap })
  const sortedMalwares = filteredMalwares.sort(
    getMalwareComparatorMap(order)[orderBy]
  )

  const handleMalwareToolsClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }

  return (
    <TableBody>
      {sortedMalwares.map((malware) => (
        // const displayDate = malware.firstSeen
        //   ? getYearAndMonthStringByTimestampsInSeconds(malware.firstSeen)
        //   : ''
        <TableRow
          key={malware.name + malware.firstSeen}
          hover
          sx={{ cursor: 'pointer', position: 'relative' }}
        >
          <MalwareTableBodyCell sx={{ borderLeft: 'none' }}>
            {malware.name}
          </MalwareTableBodyCell>
          <MalwareTableBodyCell>
            {getDisplayAlias(malware.aliases, filterTextMap.aliases)}
          </MalwareTableBodyCell>
          <MalwareTableBodyCell>{malware.type || '--'}</MalwareTableBodyCell>
          <MalwareTableBodyCell>{malware.attribution}</MalwareTableBodyCell>
          {/* <MalwareTableBodyCell>{displayDate}</MalwareTableBodyCell> */}
          <MalwareTableBodyCell>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {malware.hasTools ? (
                <Box sx={threatHuntingToolsBaseStyles}>
                  <LinkButton
                    sx={{ p: 1 }}
                    onClick={(event) => handleMalwareToolsClick(event)}
                    to={`${MALWARE_CONTENT_ROUTE}?${SearchParamKey.MALWARE_ID}=${malware.id}&${SearchParamKey.MALWARE_TAB}=threatHuntingTools`}
                  >
                    <SvgIcon
                      sx={{ width: '1rem', height: '1rem' }}
                      component={ArrowForwardIcon}
                      inheritViewBox
                    />
                  </LinkButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    ...threatHuntingToolsBaseStyles,
                    border: `1px solid ${theme.colors.DISABLE}`,
                  }}
                >
                  <SvgIcon
                    sx={{
                      width: '1rem',
                      height: '1rem',
                      path: {
                        fill: theme.colors.DISABLE,
                      },
                    }}
                    component={ArrowForwardIcon}
                    inheritViewBox
                  />
                </Box>
              )}
            </Box>
          </MalwareTableBodyCell>
          <TableCell sx={{ width: 0, p: 0 }}>
            <Link
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              href={`${MALWARES_ROUTE}/${MALWARE_CONTENT_ROUTE}?${SearchParamKey.MALWARE_ID}=${malware.id}`}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
