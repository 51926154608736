import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import {
  IFetchReportHtmlContentPayload,
  IFetchReportIocContentPayload,
  IFetchReportPdfContentPayload,
  IFetchReportsPayload,
  IPMReportDetail,
  IPMReports,
  IReport,
  TReportType,
} from 'store/types/entityTypes/report'
import { ITool } from 'store/types/entityTypes/tool'
import {
  IFetchReportsCountResponse,
  IReportSlice,
} from 'store/types/slicesTypes/report'

export const initialReportState: IReportSlice = {
  flashReports: [],
  monthlyReports: [],
  CTRReports: [],
  cyberAffairs: [],
  VIRReports: [],
  PMReports: [],
  PMReportDetail: [],
  ddwTailoredReports: [],
  isFetchFlashReportsLoading: false,
  isFetchMonthlyReportsLoading: false,
  isFetchCTRReportsLoading: false,
  isFetchCyberAffairsLoading: false,
  isFetchVIRReportsLoading: false,
  isFetchPMReportsLoading: false,
  isFetchPMReportDetailLoading: false,
  isFetchDDWTailoredReportsLoading: false,
  hasMoreFlashReports: true,
  hasMoreMonthlyReports: true,
  hasMoreCTRReports: true,
  hasMoreCyberAffairs: true,
  hasMoreVIRReports: true,
  hasMoreDDWTailoredReports: true,
  count: {
    flashReport: 0,
    monthlyReport: 0,
    CTRReport: 0,
    cyberAffairs: 0,
    VIRReport: 0,
    PMReport: 0,
    ddwTailoredReport: 0,
  },
  isCountReady: {
    flashReport: false,
    monthlyReport: false,
    CTRReport: false,
    cyberAffairs: false,
    VIRReport: false,
    PMReport: false,
    ddwTailoredReport: false,
  },
  isFlashReportCountLoading: true,
  isMonthlyReportCountLoading: true,
  isCTRReportCountLoading: true,
  isCyberAffairsCountLoading: true,
  isVIRReportCountLoading: true,
  isPMReportCountLoading: true,
  isDDWTailoredReportCountLoading: true,
  reportHtmlContent: null,
  reportIocContent: null,
  reportPdfContent: null,
  isFetchReportHtmlContentLoading: false,
  isFetchReportIocContentLoading: false,
  isFetchReportPdfContentLoading: false,
  isFetchReportPdfContentLoadingCount: 0,
  isFetchReportIocContentLoadingCount: 0,
  report: null,
  isFetchReportLoading: false,
  reportReadableTypes: {
    flashReport: false,
    cyberAffairs: false,
    monthlyReport: false,
    CTRReport: false,
    VIRReport: false,
    PMReport: false,
  },
  isFetchReportReadableTypesLoading: true,
  reportTools: [],
  reportToolsLoading: true,
}

const reportSlice = createSlice({
  name: 'report',
  initialState: initialReportState,
  reducers: {
    fetchFlashReports(
      state,
      _action: PayloadAction<IFetchReportsPayload | undefined>
    ) {
      state.isFetchFlashReportsLoading = true
    },
    fetchFlashReportsFulfilled(state, action: PayloadAction<IReport[]>) {
      // 抓不到值代表沒有更多的 report
      if (action.payload.length === 0) {
        state.hasMoreFlashReports = false
      } else {
        state.flashReports = [...state.flashReports, ...action.payload]
      }
      state.isFetchFlashReportsLoading = false
    },
    fetchFlashReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.flashReports = initialReportState.flashReports
      state.isFetchFlashReportsLoading = false
    },
    fetchFlashReportsCancelled(state) {
      state.isFetchFlashReportsLoading = false
    },
    fetchMonthlyReports(
      state,
      _action: PayloadAction<IFetchReportsPayload | undefined>
    ) {
      state.isFetchMonthlyReportsLoading = true
    },
    fetchMonthlyReportsFulfilled(state, action: PayloadAction<IReport[]>) {
      // 抓不到值代表沒有更多的 report
      if (action.payload.length === 0) {
        state.hasMoreMonthlyReports = false
      } else {
        state.monthlyReports = [...state.monthlyReports, ...action.payload]
      }
      state.isFetchMonthlyReportsLoading = false
    },
    fetchMonthlyReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.monthlyReports = initialReportState.monthlyReports
      state.isFetchMonthlyReportsLoading = false
    },
    fetchMonthlyReportsCancelled(state) {
      state.isFetchMonthlyReportsLoading = false
    },
    fetchCTRReports(
      state,
      _action: PayloadAction<IFetchReportsPayload | undefined>
    ) {
      state.isFetchCTRReportsLoading = true
    },
    fetchCTRReportsFulfilled(state, action: PayloadAction<IReport[]>) {
      // 抓不到值代表沒有更多的 report
      if (action.payload.length === 0) {
        state.hasMoreCTRReports = false
      } else {
        state.CTRReports = [...state.CTRReports, ...action.payload]
      }
      state.isFetchCTRReportsLoading = false
    },
    fetchCTRReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.CTRReports = initialReportState.CTRReports
      state.isFetchCTRReportsLoading = false
    },
    fetchCTRReportsCancelled(state) {
      state.isFetchCTRReportsLoading = false
    },
    fetchCyberAffairs(
      state,
      _action: PayloadAction<IFetchReportsPayload | undefined>
    ) {
      state.isFetchCyberAffairsLoading = true
    },
    fetchCyberAffairsFulfilled(state, action: PayloadAction<IReport[]>) {
      if (action.payload.length === 0) {
        state.hasMoreCyberAffairs = false
      } else {
        state.cyberAffairs = [...state.cyberAffairs, ...action.payload]
      }
      state.isFetchCyberAffairsLoading = false
    },
    fetchCyberAffairsRejected(state, _action: PayloadAction<AjaxError>) {
      state.cyberAffairs = initialReportState.cyberAffairs
      state.isFetchCyberAffairsLoading = false
    },
    fetchCyberAffairsCancelled(state) {
      state.isFetchCyberAffairsLoading = false
    },
    fetchVIRReports(
      state,
      _action: PayloadAction<IFetchReportsPayload | undefined>
    ) {
      state.isFetchVIRReportsLoading = true
    },
    fetchVIRReportsFulfilled(state, action: PayloadAction<IReport[]>) {
      // 抓不到值代表沒有更多的 report
      if (action.payload.length === 0) {
        state.hasMoreVIRReports = false
      } else {
        state.VIRReports = [...state.VIRReports, ...action.payload]
      }
      state.isFetchVIRReportsLoading = false
    },
    fetchVIRReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.VIRReports = initialReportState.VIRReports
      state.isFetchVIRReportsLoading = false
    },
    fetchVIRReportsCancelled(state) {
      state.isFetchVIRReportsLoading = false
    },
    fetchPMReports(state) {
      state.isFetchPMReportsLoading = true
    },
    fetchPMReportsFulfilled(state, action: PayloadAction<IPMReports[]>) {
      state.PMReports = action.payload
      state.isFetchPMReportsLoading = false
    },
    fetchPMReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.PMReports = initialReportState.PMReports
      state.isFetchPMReportsLoading = false
    },
    fetchPMReportsCancelled(state) {
      state.isFetchPMReportsLoading = false
    },
    fetchPMReportDetail(state, _action: PayloadAction<string>) {
      state.isFetchPMReportDetailLoading = true
    },
    fetchPMReportDetailFulfilled(
      state,
      action: PayloadAction<IPMReportDetail[]>
    ) {
      state.PMReportDetail = action.payload

      state.isFetchPMReportDetailLoading = false
    },
    fetchPMReportDetailRejected(state, _action: PayloadAction<AjaxError>) {
      state.PMReportDetail = initialReportState.PMReportDetail
      state.isFetchPMReportDetailLoading = false
    },
    fetchPMReportDetailCancelled() {},
    fetchFlashReportsCount(state) {
      state.isFlashReportCountLoading = true
    },
    fetchDDWTailoredReports(
      state,
      _action: PayloadAction<IFetchReportsPayload | undefined>
    ) {
      state.isFetchDDWTailoredReportsLoading = true
    },
    fetchDDWTailoredReportsFulfilled(state, action: PayloadAction<IReport[]>) {
      if (action.payload.length === 0) {
        state.hasMoreDDWTailoredReports = false
      } else {
        state.ddwTailoredReports = [
          ...state.ddwTailoredReports,
          ...action.payload,
        ]
      }
      state.isFetchDDWTailoredReportsLoading = false
    },
    fetchDDWTailoredReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.ddwTailoredReports = initialReportState.ddwTailoredReports
      state.isFetchDDWTailoredReportsLoading = false
    },
    resetDDWTailoredReport(state) {
      state.hasMoreDDWTailoredReports =
        initialReportState.hasMoreDDWTailoredReports
      state.ddwTailoredReports = initialReportState.ddwTailoredReports
    },
    fetchDDWTailoredReportsCancelled(state) {
      state.isFetchDDWTailoredReportsLoading = false
    },
    fetchFlashReportsCountFulfilled(
      state,
      action: PayloadAction<IFetchReportsCountResponse>
    ) {
      state.count.flashReport = action.payload.count
      state.isFlashReportCountLoading = false
      state.isCountReady.flashReport = true
    },
    fetchFlashReportsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.count.flashReport = initialReportState.count.flashReport
      state.isFlashReportCountLoading = false
    },
    fetchFlashReportsCountCancelled(state) {
      state.isFlashReportCountLoading = false
    },
    fetchMonthlyReportsCount(state) {
      state.isMonthlyReportCountLoading = true
    },
    fetchMonthlyReportsCountFulfilled(
      state,
      action: PayloadAction<IFetchReportsCountResponse>
    ) {
      state.count.monthlyReport = action.payload.count
      state.isMonthlyReportCountLoading = false
      state.isCountReady.monthlyReport = true
    },
    fetchMonthlyReportsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.count.monthlyReport = initialReportState.count.monthlyReport
      state.isMonthlyReportCountLoading = false
    },
    fetchMonthlyReportsCountCancelled(state) {
      state.isMonthlyReportCountLoading = false
    },
    fetchCTRReportsCount(state) {
      state.isCTRReportCountLoading = true
    },
    fetchCTRReportsCountFulfilled(
      state,
      action: PayloadAction<IFetchReportsCountResponse>
    ) {
      state.count.CTRReport = action.payload.count
      state.isCTRReportCountLoading = false
      state.isCountReady.CTRReport = true
    },
    fetchCTRReportsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.count.CTRReport = initialReportState.count.CTRReport
      state.isCTRReportCountLoading = false
    },
    fetchCTRReportsCountCancelled(state) {
      state.isCTRReportCountLoading = false
    },
    fetchCyberAffairsCount(state) {
      state.isCyberAffairsCountLoading = true
    },
    fetchCyberAffairsCountFulfilled(
      state,
      action: PayloadAction<IFetchReportsCountResponse>
    ) {
      state.count.cyberAffairs = action.payload.count
      state.isCyberAffairsCountLoading = false
      state.isCountReady.cyberAffairs = true
    },
    fetchCyberAffairsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.count.cyberAffairs = initialReportState.count.cyberAffairs
      state.isCyberAffairsCountLoading = false
    },
    fetchCyberAffairsCountCancelled(state) {
      state.isCyberAffairsCountLoading = false
    },
    fetchVIRReportsCount(state) {
      state.isVIRReportCountLoading = true
    },
    fetchVIRReportsCountFulfilled(
      state,
      action: PayloadAction<IFetchReportsCountResponse>
    ) {
      state.count.VIRReport = action.payload.count
      state.isVIRReportCountLoading = false
      state.isCountReady.VIRReport = true
    },
    fetchVIRReportsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.count.VIRReport = initialReportState.count.VIRReport
      state.isVIRReportCountLoading = false
    },
    fetchVIRReportsCountCancelled(state) {
      state.isVIRReportCountLoading = false
    },
    fetchPMReportsCount(state) {
      state.isPMReportCountLoading = true
    },
    fetchPMReportsCountFulfilled(
      state,
      action: PayloadAction<IFetchReportsCountResponse>
    ) {
      state.count.PMReport = action.payload.count
      state.isPMReportCountLoading = false
      state.isCountReady.PMReport = true
    },
    fetchPMReportsCountRejected(state, _action: PayloadAction<AjaxError>) {
      state.count.PMReport = initialReportState.count.PMReport
      state.isPMReportCountLoading = false
    },
    fetchPMReportsCountCancelled() {},
    fetchDDWTailoredReportsCount(state) {
      state.isDDWTailoredReportCountLoading = true
    },
    fetchDDWTailoredReportsCountFulfilled(
      state,
      action: PayloadAction<IFetchReportsCountResponse>
    ) {
      state.count.ddwTailoredReport = action.payload.count
      state.isDDWTailoredReportCountLoading = false
      state.isCountReady.ddwTailoredReport = true
    },
    fetchDDWTailoredReportsCountRejected(
      state,
      _action: PayloadAction<AjaxError>
    ) {
      state.count.ddwTailoredReport = initialReportState.count.ddwTailoredReport
      state.isDDWTailoredReportCountLoading = false
    },
    fetchDDWTailoredReportsCountCancelled(state) {
      state.isDDWTailoredReportCountLoading = false
    },
    resetIsCountReady(state) {
      state.isCountReady = initialReportState.isCountReady
    },
    resetFlashReports(state) {
      state.hasMoreFlashReports = initialReportState.hasMoreFlashReports
      state.flashReports = initialReportState.flashReports
    },
    resetMonthlyReports(state) {
      state.hasMoreMonthlyReports = initialReportState.hasMoreMonthlyReports
      state.monthlyReports = initialReportState.monthlyReports
    },
    resetCTRReports(state) {
      state.hasMoreCTRReports = initialReportState.hasMoreCTRReports
      state.CTRReports = initialReportState.CTRReports
    },
    resetCyberAffairs(state) {
      state.hasMoreCyberAffairs = initialReportState.hasMoreCyberAffairs
      state.cyberAffairs = initialReportState.cyberAffairs
    },
    resetVIRReports(state) {
      state.hasMoreVIRReports = initialReportState.hasMoreVIRReports
      state.VIRReports = initialReportState.VIRReports
    },
    fetchReportHtmlContent(
      state,
      _action: PayloadAction<IFetchReportHtmlContentPayload>
    ) {
      state.isFetchReportHtmlContentLoading = true
    },
    fetchReportHtmlContentFulfilled(state, action: PayloadAction<string>) {
      state.reportHtmlContent = action.payload
      state.isFetchReportHtmlContentLoading = false
    },
    fetchReportHtmlContentRejected(state, _action: PayloadAction<AjaxError>) {
      state.reportHtmlContent = initialReportState.reportHtmlContent
      state.isFetchReportHtmlContentLoading = false
    },
    fetchReportHtmlContentCancelled(state) {
      state.isFetchReportHtmlContentLoading = false
    },
    fetchReportIocContent(
      state,
      _action: PayloadAction<IFetchReportIocContentPayload>
    ) {
      state.isFetchReportIocContentLoading = true
      state.isFetchReportIocContentLoadingCount += 1
    },
    fetchReportIocContentFulfilled(state, action: PayloadAction<string>) {
      state.reportIocContent = action.payload
      state.isFetchReportIocContentLoading = false
      state.isFetchReportIocContentLoadingCount -= 1
    },
    fetchReportIocContentRejected(state, _action: PayloadAction<AjaxError>) {
      state.reportIocContent = initialReportState.reportIocContent
      state.isFetchReportIocContentLoading = false
      state.isFetchReportIocContentLoadingCount -= 1
    },
    fetchReportIocContentCancelled(state) {
      state.isFetchReportIocContentLoading = false
    },
    fetchReportPdfContent(
      state,
      _action: PayloadAction<IFetchReportPdfContentPayload>
    ) {
      state.isFetchReportPdfContentLoading = true
      state.isFetchReportPdfContentLoadingCount += 1
    },
    fetchReportPdfContentFulfilled(state, action: PayloadAction<ArrayBuffer>) {
      state.reportPdfContent = action.payload
      state.isFetchReportPdfContentLoading = false
      state.isFetchReportPdfContentLoadingCount -= 1
    },
    fetchReportPdfContentRejected(state, _action: PayloadAction<AjaxError>) {
      state.reportPdfContent = initialReportState.reportPdfContent
      state.isFetchReportPdfContentLoading = false
      state.isFetchReportPdfContentLoadingCount -= 1
    },
    fetchReportPdfContentCancelled(state) {
      state.isFetchReportPdfContentLoading = false
    },
    fetchReport(state, _action: PayloadAction<IFetchReportHtmlContentPayload>) {
      state.isFetchReportLoading = true
    },
    fetchReportFulfilled(state, action: PayloadAction<IReport>) {
      state.report = action.payload
      state.isFetchReportLoading = false
    },
    fetchReportRejected(state, _action: PayloadAction<AjaxError>) {
      state.report = initialReportState.report
      state.isFetchReportLoading = false
    },
    fetchReportCancelled() {},
    fetchReportReadableTypes(state) {
      state.isFetchReportReadableTypesLoading = true
    },
    fetchReportReadableTypesFulfilled(
      state,
      action: PayloadAction<
        Record<
          Exclude<TReportType, 'customReport' | 'ddwTailoredReport'>,
          boolean
        >
      >
    ) {
      state.reportReadableTypes = action.payload
      state.isFetchReportReadableTypesLoading = false
    },
    fetchReportReadableTypesRejected(state, _action: PayloadAction<AjaxError>) {
      state.reportReadableTypes = initialReportState.reportReadableTypes
      state.isFetchReportReadableTypesLoading = false
    },
    fetchReportReadableTypesCancelled(state) {
      state.isFetchReportReadableTypesLoading = false
    },
    fetchReportTools(state, _action: PayloadAction<string>) {
      state.reportToolsLoading = true
    },
    fetchReportToolsFulfilled(state, action: PayloadAction<ITool[]>) {
      state.reportTools = action.payload
      state.reportToolsLoading = false
    },
    fetchReportToolsRejected(state, _action: PayloadAction<AjaxError>) {
      state.reportTools = initialReportState.reportTools
      state.reportToolsLoading = false
    },
    fetchReportToolsCancelled(state) {
      state.reportToolsLoading = false
    },
    resetReportTools(state) {
      state.reportTools = initialReportState.reportTools
    },
  },
})

export const {
  fetchFlashReports,
  fetchFlashReportsFulfilled,
  fetchFlashReportsRejected,
  fetchFlashReportsCancelled,
  fetchMonthlyReports,
  fetchMonthlyReportsFulfilled,
  fetchMonthlyReportsRejected,
  fetchMonthlyReportsCancelled,
  fetchCTRReports,
  fetchCTRReportsFulfilled,
  fetchCTRReportsRejected,
  fetchCTRReportsCancelled,
  fetchCyberAffairs,
  fetchCyberAffairsFulfilled,
  fetchCyberAffairsRejected,
  fetchCyberAffairsCancelled,
  fetchVIRReports,
  fetchVIRReportsFulfilled,
  fetchVIRReportsRejected,
  fetchVIRReportsCancelled,
  fetchPMReports,
  fetchPMReportsFulfilled,
  fetchPMReportsRejected,
  fetchPMReportsCancelled,
  fetchPMReportDetail,
  fetchPMReportDetailFulfilled,
  fetchPMReportDetailRejected,
  fetchPMReportDetailCancelled,
  fetchDDWTailoredReports,
  fetchDDWTailoredReportsFulfilled,
  fetchDDWTailoredReportsRejected,
  fetchDDWTailoredReportsCancelled,
  resetDDWTailoredReport,
  fetchFlashReportsCount,
  fetchFlashReportsCountFulfilled,
  fetchFlashReportsCountRejected,
  fetchFlashReportsCountCancelled,
  fetchMonthlyReportsCount,
  fetchMonthlyReportsCountFulfilled,
  fetchMonthlyReportsCountRejected,
  fetchMonthlyReportsCountCancelled,
  fetchCTRReportsCount,
  fetchCTRReportsCountFulfilled,
  fetchCTRReportsCountRejected,
  fetchCTRReportsCountCancelled,
  fetchCyberAffairsCount,
  fetchCyberAffairsCountFulfilled,
  fetchCyberAffairsCountRejected,
  fetchCyberAffairsCountCancelled,
  fetchVIRReportsCount,
  fetchVIRReportsCountFulfilled,
  fetchVIRReportsCountRejected,
  fetchVIRReportsCountCancelled,
  fetchPMReportsCount,
  fetchPMReportsCountFulfilled,
  fetchPMReportsCountRejected,
  fetchPMReportsCountCancelled,
  fetchDDWTailoredReportsCount,
  fetchDDWTailoredReportsCountFulfilled,
  fetchDDWTailoredReportsCountRejected,
  fetchDDWTailoredReportsCountCancelled,
  resetIsCountReady,
  resetFlashReports,
  resetMonthlyReports,
  resetCTRReports,
  resetCyberAffairs,
  resetVIRReports,
  fetchReportHtmlContent,
  fetchReportHtmlContentFulfilled,
  fetchReportHtmlContentRejected,
  fetchReportHtmlContentCancelled,
  fetchReportIocContent,
  fetchReportIocContentFulfilled,
  fetchReportIocContentRejected,
  fetchReportIocContentCancelled,
  fetchReportPdfContent,
  fetchReportPdfContentFulfilled,
  fetchReportPdfContentRejected,
  fetchReportPdfContentCancelled,
  fetchReport,
  fetchReportFulfilled,
  fetchReportRejected,
  fetchReportCancelled,
  fetchReportReadableTypes,
  fetchReportReadableTypesFulfilled,
  fetchReportReadableTypesRejected,
  fetchReportReadableTypesCancelled,
  fetchReportTools,
  fetchReportToolsFulfilled,
  fetchReportToolsRejected,
  fetchReportToolsCancelled,
  resetReportTools,
} = reportSlice.actions

export const selectFlashReports = (state: TRootState) =>
  state.report.flashReports

export const selectMonthlyReports = (state: TRootState) =>
  state.report.monthlyReports

export const selectCTRReports = (state: TRootState) => state.report.CTRReports

export const selectVIRReports = (state: TRootState) => state.report.VIRReports

export const selectCyberAffairs = (state: TRootState) =>
  state.report.cyberAffairs

export const selectPMReports = (state: TRootState) => state.report.PMReports

export const selectDDWTailoredReport = (state: TRootState) =>
  state.report.ddwTailoredReports

export const selectPMReportDetail = (state: TRootState) =>
  state.report.PMReportDetail

export const selectReportTypeCount = (state: TRootState) => {
  if (
    !state.report.isFlashReportCountLoading &&
    !state.report.isMonthlyReportCountLoading &&
    !state.report.isCTRReportCountLoading &&
    !state.report.isCyberAffairsCountLoading &&
    !state.report.isVIRReportCountLoading &&
    !state.report.isPMReportCountLoading
  ) {
    return state.report.count
  }
  return null
}

export const selectReportCategoryCount = (state: TRootState) => {
  if (
    !state.report.isFlashReportCountLoading &&
    !state.report.isMonthlyReportCountLoading &&
    !state.report.isCTRReportCountLoading &&
    !state.report.isCyberAffairsCountLoading &&
    !state.report.isVIRReportCountLoading &&
    !state.report.isPMReportCountLoading
  ) {
    return {
      APT:
        state.report.count.flashReport +
        state.report.count.monthlyReport +
        state.report.count.CTRReport,
      cyberAffairs: state.report.count.cyberAffairs,
      vulnerability: state.report.count.VIRReport + state.report.count.PMReport,
    }
  }
  return null
}

export const selectAPTReportTypeCount = (state: TRootState) => {
  if (
    !state.report.isFlashReportCountLoading &&
    !state.report.isMonthlyReportCountLoading &&
    !state.report.isCTRReportCountLoading
  ) {
    return {
      flashReport: state.report.count.flashReport,
      monthlyReport: state.report.count.monthlyReport,
      CTRReport: state.report.count.CTRReport,
    }
  }
  return null
}

export const selectVulnerabilityReportTypeCount = (state: TRootState) => {
  if (
    !state.report.isVIRReportCountLoading &&
    !state.report.isPMReportCountLoading
  ) {
    return {
      VIRReport: state.report.count.VIRReport,
      PMReport: state.report.count.PMReport,
    }
  }
  return null
}

export const selectIsCountReady = (state: TRootState) =>
  state.report.isCountReady

export const selectHasMoreFlashReports = (state: TRootState) =>
  state.report.hasMoreFlashReports

export const selectHasMoreMonthlyReports = (state: TRootState) =>
  state.report.hasMoreMonthlyReports

export const selectHasMoreCTRReports = (state: TRootState) =>
  state.report.hasMoreCTRReports

export const selectHasMoreCyberAffairs = (state: TRootState) =>
  state.report.hasMoreCyberAffairs

export const selectHasMoreVIRReports = (state: TRootState) =>
  state.report.hasMoreVIRReports

export const selectHasMoreDDWTailoredReport = (state: TRootState) =>
  state.report.hasMoreDDWTailoredReports

export const selectIsFetchFlashReportsLoading = (state: TRootState) =>
  state.report.isFetchFlashReportsLoading

export const selectIsFetchMonthlyReportsLoading = (state: TRootState) =>
  state.report.isFetchMonthlyReportsLoading

export const selectIsFetchCTRReportsLoading = (state: TRootState) =>
  state.report.isFetchCTRReportsLoading

export const selectIsFetchCyberAffairsLoading = (state: TRootState) =>
  state.report.isFetchCyberAffairsLoading

export const selectIsFetchVIRReportsLoading = (state: TRootState) =>
  state.report.isFetchVIRReportsLoading

export const selectIsFetchPMReportsLoading = (state: TRootState) =>
  state.report.isFetchPMReportsLoading

export const selectIsFetchDDWTailoredReportLoading = (state: TRootState) =>
  state.report.isFetchDDWTailoredReportsLoading

export const selectIsFetchPMReportDetailLoading = (state: TRootState) =>
  state.report.isFetchPMReportDetailLoading

export const selectReportHtmlContent = (state: TRootState) =>
  state.report.reportHtmlContent

export const selectIsFetchReportHtmlContentLoading = (state: TRootState) =>
  state.report.isFetchReportHtmlContentLoading

export const selectReportIocContent = (state: TRootState) =>
  state.report.reportIocContent

export const selectIsFetchReportIocContentLoading = (state: TRootState) =>
  state.report.isFetchReportIocContentLoading

export const selectReportPdfContent = (state: TRootState) =>
  state.report.reportPdfContent

export const selectIsFetchReportPdfContentLoading = (state: TRootState) =>
  state.report.isFetchReportPdfContentLoading

export const selectIsFetchReportPdfContentLoadingCount = (state: TRootState) =>
  state.report.isFetchReportPdfContentLoadingCount

export const selectIsFetchReportIocContentLoadingCount = (state: TRootState) =>
  state.report.isFetchReportIocContentLoadingCount

export const selectReport = (state: TRootState) => state.report.report

export const selectIsFetchReportLoading = (state: TRootState) =>
  state.report.isFetchReportLoading

export const selectReportReadableTypes = (state: TRootState) =>
  state.report.reportReadableTypes

export const selectReportReadableCategories = (state: TRootState) => ({
  APT:
    state.report.reportReadableTypes.flashReport ||
    state.report.reportReadableTypes.monthlyReport ||
    state.report.reportReadableTypes.CTRReport,
  cyberAffairs: state.report.reportReadableTypes.cyberAffairs,
  vulnerability:
    state.report.reportReadableTypes.VIRReport ||
    state.report.reportReadableTypes.PMReport,
})

export const selectIsFetchReportReadableTypesLoading = (state: TRootState) =>
  state.report.isFetchReportReadableTypesLoading

export const selectReportToolsLoading = (state: TRootState) =>
  state.report.reportToolsLoading

export const selectReportFirstTool = (state: TRootState) =>
  state.report.reportTools[0]

export default reportSlice.reducer
