import {
  TIocSubscriberGroupsKey,
  TIocSubscriberGroupsName,
  TOtherServicesSubscriberGroupsKey,
  TOtherServicesSubscriberGroupsName,
  TReportSubscriberGroupsKey,
  TReportSubscriberGroupsName,
  TShareTargetsType,
  TTlp,
} from './tlpTarget'

export type TLanguages = 'en' | 'zh-TW' | 'ja'
export type TLocale = TLanguages | null

export interface IAAPAPIData {
  used: number
  total: number | null
  main_group_expires_at: number | null
}

export interface IRAHAPIData {
  used: number
  total: number
  suggested_expires_at: number | null
}

export interface IAAP {
  used: number
  total: number | null
  expiresAt: number | null
}

export interface IRAH {
  used: number
  total: number
  expiresAt: number | null
}

export interface IAccountSettingsAPIData {
  avatar: string | null
  email: string | null
  tlp: TTlp
  share_targets: {
    name: string
    avatar: string
    type: TShareTargetsType
    ofs_id: string
    can_edit: boolean
  }[]
  main_group: {
    id: number
    name: string
    joined_at: number
    expires_at: number | null
  } | null
  report_subscriber_groups:
    | {
        name: TReportSubscriberGroupsName
        key: TReportSubscriberGroupsKey
        joined_at: number
        expires_at: number | null
      }[]
    | null
  ioc_subscriber_groups:
    | {
        name: TIocSubscriberGroupsName
        key: TIocSubscriberGroupsKey
        joined_at: number
        expires_at: number | null
      }[]
    | null
  other_services_subscriber_groups:
    | {
        name: TOtherServicesSubscriberGroupsName
        key: TOtherServicesSubscriberGroupsKey
        joined_at: number
        expires_at: number | null
      }[]
    | null
  mssp_user: boolean
  automated_analysis_point: IAAPAPIData
  request_for_assistance_hour: IRAHAPIData
  report_languages: TLanguages[] | null
  locale: TLocale
  timezone: string | null
  tzinfo: string | null
  mfa_popup: boolean
}

export interface IAccountSettings {
  avatar: string | null
  email: string | null
  tlp: TTlp | null
  shareTargets: {
    name: string
    avatar: string
    type: TShareTargetsType
    ofsId: string
    canEdit: boolean
  }[]
  mainGroup: {
    id: number
    name: string
    joinedAt: number
    expiresAt: number | null
  } | null
  reportSubscriberGroups:
    | {
        name: TReportSubscriberGroupsName
        key: TReportSubscriberGroupsKey
        joinedAt: number
        expiresAt: number | null
      }[]
    | null
  iocSubscriberGroups:
    | {
        name: TIocSubscriberGroupsName
        key: TIocSubscriberGroupsKey
        joinedAt: number
        expiresAt: number | null
      }[]
    | null
  otherServicesSubscriberGroups:
    | {
        name: TOtherServicesSubscriberGroupsName
        key: TOtherServicesSubscriberGroupsKey
        joinedAt: number
        expiresAt: number | null
      }[]
    | null
  msspUser: boolean
  automatedAnalysisPoint: IAAP
  requestForAssistanceHour: IRAH
  reportLanguages: TLanguages[]
  locale: TLocale
  timezone: string | null
  tzinfo: string | null
  mfaPopup: boolean
}

export interface IAccountSettingsOptionsAPIData {
  report_languages: {
    value: TLanguages
    display_value: string
  }[]
  locales: {
    value: TLocale
    display_value: string
  }[]
  timezones: {
    value: string | null
    display_value: string
  }[]
}

export interface IAccountSettingsOptions {
  reportLanguages: {
    value: TLanguages
    displayValue: string
  }[]
  locales: {
    value: TLocale
    displayValue: string
  }[]
  timezones: {
    value: string | null
    displayValue: string
  }[]
}

export interface IAccountAPIKeysAPIData {
  enable: boolean
  client_id: string
  client_secret: string
}

export interface IAccountAPIKeys {
  enable: boolean
  clientId: string
  clientSecret: string
}

export interface IAccountOnDemandReportsAPIData {
  main_value: string
  title: string
  alias: string
  date: number
  request_for_assistance_hour: number
}

export interface IAccountOnDemandReports {
  id: string
  title: string
  alias: string
  date: number
  requestForAssistanceHour: number
}

export interface IAccountMFASettingsAPIData {
  mfa_enable: boolean | null
  mfa_enabled_at: string | null
}

export interface IAccountMFASettings {
  mfaEnable: boolean | null
  mfaEnabledAt: string | null
}

export interface IAccountMFACreateAPIData {
  email: string
  secret: string
  qr_data: string
}

export interface IAccountMFACreate {
  email: string
  secret: string
  qrData: string
}

export const mapAPIAccountSettingsToState = (
  fromAPIAccountSettings: IAccountSettingsAPIData
): IAccountSettings => ({
  avatar: fromAPIAccountSettings.avatar,
  email: fromAPIAccountSettings.email,
  tlp: fromAPIAccountSettings.tlp,
  locale: fromAPIAccountSettings.locale,
  timezone: fromAPIAccountSettings.timezone,
  tzinfo: fromAPIAccountSettings.tzinfo,
  shareTargets: fromAPIAccountSettings.share_targets.map((shareTarget) => ({
    ...shareTarget,
    canEdit: shareTarget.can_edit,
    ofsId: shareTarget.ofs_id,
  })),
  mainGroup: fromAPIAccountSettings.main_group
    ? {
        id: fromAPIAccountSettings.main_group.id,
        name: fromAPIAccountSettings.main_group.name,
        joinedAt: fromAPIAccountSettings.main_group.joined_at,
        expiresAt: fromAPIAccountSettings.main_group.expires_at,
      }
    : null,
  reportSubscriberGroups:
    fromAPIAccountSettings.report_subscriber_groups?.map(
      (reportSubscriberGroup) => ({
        name: reportSubscriberGroup.name,
        key: reportSubscriberGroup.key,
        joinedAt: reportSubscriberGroup.joined_at,
        expiresAt: reportSubscriberGroup.expires_at,
      })
    ) || null,
  iocSubscriberGroups:
    fromAPIAccountSettings.ioc_subscriber_groups?.map((iocSubscriberGroup) => ({
      name: iocSubscriberGroup.name,
      key: iocSubscriberGroup.key,
      joinedAt: iocSubscriberGroup.joined_at,
      expiresAt: iocSubscriberGroup.expires_at,
    })) || null,
  otherServicesSubscriberGroups:
    fromAPIAccountSettings.other_services_subscriber_groups?.map(
      (otherServicesSubscriberGroup) => ({
        name: otherServicesSubscriberGroup.name,
        key: otherServicesSubscriberGroup.key,
        joinedAt: otherServicesSubscriberGroup.joined_at,
        expiresAt: otherServicesSubscriberGroup.expires_at,
      })
    ) || null,
  msspUser: fromAPIAccountSettings.mssp_user,
  automatedAnalysisPoint: {
    used: fromAPIAccountSettings.automated_analysis_point.used,
    total: fromAPIAccountSettings.automated_analysis_point.total,
    expiresAt:
      fromAPIAccountSettings.automated_analysis_point.main_group_expires_at,
  },
  requestForAssistanceHour: {
    used: fromAPIAccountSettings.request_for_assistance_hour.used,
    total: fromAPIAccountSettings.request_for_assistance_hour.total,
    expiresAt:
      fromAPIAccountSettings.request_for_assistance_hour.suggested_expires_at,
  },
  reportLanguages: fromAPIAccountSettings.report_languages || [
    'en',
    'ja',
    'zh-TW',
  ],
  mfaPopup: fromAPIAccountSettings.mfa_popup,
})

export const mapAPIAccountSettingsOptionsToState = (
  fromAPIAccountSettingsOptions: IAccountSettingsOptionsAPIData
): IAccountSettingsOptions => ({
  reportLanguages: fromAPIAccountSettingsOptions.report_languages.map(
    (reportLanguage) => ({
      ...reportLanguage,
      displayValue: reportLanguage.display_value,
    })
  ),
  locales: fromAPIAccountSettingsOptions.locales.map((locale) => ({
    ...locale,
    displayValue: locale.display_value,
  })),
  timezones: fromAPIAccountSettingsOptions.timezones.map((timezone) => ({
    ...timezone,
    displayValue: timezone.display_value,
  })),
})

export const mapAPIAccountAPIKeysToState = (
  fromAPIAccountAPIKeys: IAccountAPIKeysAPIData
): IAccountAPIKeys => ({
  enable: fromAPIAccountAPIKeys.enable,
  clientId: fromAPIAccountAPIKeys.client_id,
  clientSecret: fromAPIAccountAPIKeys.client_secret,
})

export const mapAPIAccountOnDemandReportsToState = (
  fromAPIAccountOnDemandReports: IAccountOnDemandReportsAPIData[]
): IAccountOnDemandReports[] =>
  fromAPIAccountOnDemandReports.map((fromAPIAccountOnDemandReport) => ({
    id: fromAPIAccountOnDemandReport.main_value,
    title: fromAPIAccountOnDemandReport.title,
    alias: fromAPIAccountOnDemandReport.alias,
    date: fromAPIAccountOnDemandReport.date,
    requestForAssistanceHour:
      fromAPIAccountOnDemandReport.request_for_assistance_hour,
  }))

export const mapAPIAccountMFASettingsToState = (
  fromAPIAccountMFASettings: IAccountMFASettingsAPIData
): IAccountMFASettings => ({
  mfaEnable: fromAPIAccountMFASettings.mfa_enable,
  mfaEnabledAt: fromAPIAccountMFASettings.mfa_enabled_at,
})

export const mapAPIAccountMFACreateToState = (
  fromAPIAccountMFACreate: IAccountMFACreateAPIData
): IAccountMFACreate => ({
  email: fromAPIAccountMFACreate.email,
  secret: fromAPIAccountMFACreate.secret,
  qrData: fromAPIAccountMFACreate.qr_data,
})
