// 34.5rem = AccordionNavbar(17.5rem) + Navbar(2rem) + TocList(15rem)
export enum DocumentContentWidth {
  WIDTH = 'calc(100vw - 34.5rem)',
  MAX_WIDTH = 'calc(1920px - 34.5rem)',
  MIN_WIDTH = 'calc(1280px - 34.5rem)',
}

export enum ScrollableContainerId {
  TOP_ID = 'content-container-top',
  BOTTOM_ID = 'content-container-bottom',
}

export enum DocumentName {
  USER_MANUAL_DOCUMENT_FILENAME_EN = 'ThreatVision_UserManual_v1.1_20230814_enUS.pdf',
  USER_MANUAL_DOCUMENT_FILENAME_ZH = 'ThreatVision_UserManual_v1.1_20230814_zhTW.pdf',
}

export enum DocumentTimestamp {
  // 2024-09-03 00:00(UTC+8) timestamp
  API_DOCUMENT_TIMESTAMP = 1725321600,

  // 2023-04-14 00:00(UTC+8) timestamp
  EULA_DOCUMENT_TIMESTAMP = 1681401600,

  // 2022-10-03 00:00(UTC+8) timestamp
  TERMS_OF_SERVICE_DOCUMENT_TIMESTAMP = 1664726400,

  // 2022-10-03 00:00(UTC+8) timestamp
  PRIVACY_POLICY_DOCUMENT_TIMESTAMP = 1664726400,

  // 2023-08-30 00:00(UTC+8) timestamp
  USER_MANUAL_DOCUMENT_TIMESTAMP = 1693324800,

  // 2023-11-06 00:00(UTC+8) timestamp
  REPORT_INTRODUCTION_TIMESTAMP = 1699200000,

  // TODO: 日期待定
  // 2024-09-30 00:00(UTC+8) timestamp
  MFA_INFORMATION_TIMESTAMP = 1727668800,
}
