import type { Action } from '@reduxjs/toolkit'
import { LOGIN } from 'constants/api'
import { StatusCode } from 'constants/statusCode'
import i18n from 'i18next'
import { combineEpics } from 'redux-observable'
import {
  catchError,
  concat,
  delay,
  EMPTY,
  filter,
  iif,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import type { TAppEpic } from 'store'
import {
  login,
  loginCancelled,
  loginFulfilled,
  loginRejected,
  logout,
  setAfterLogin,
} from 'store/slices/auth'
import { pushAlertSnackbar, pushSuccessSnackbar } from 'store/slices/snackbar'
import { IAuthAPIResponse } from 'store/types/slicesTypes/auth'
import {
  clearAuthToken,
  getPathBeforeLogout,
  setAuthToken,
  setPathBeforeLogout,
} from 'utils/auth'
import { fetchData } from 'utils/fetch.utils'

const loginEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(login.match),
    switchMap((action) =>
      fetchData<IAuthAPIResponse>({
        method: 'POST',
        url: LOGIN,
        body: action.payload,
      }).pipe(
        mergeMap((response: AjaxResponse<IAuthAPIResponse>) =>
          concat(
            of(loginFulfilled(response.response)).pipe(
              tap(() => {
                setAuthToken(response.response)
              })
            ),
            of(setAfterLogin(true)),
            of(
              pushSuccessSnackbar({
                text: i18n.t('login.loginSuccess', { ns: 'snackbar' }),
              })
            ).pipe(
              delay(3000), // 預設三秒內為剛登入狀態
              map(() => setAfterLogin(false))
            )
          )
        ),
        takeUntil(action$.pipe(filter(loginCancelled.match))),
        catchError((error) => {
          if (error.response.status_code === StatusCode.LOGIN_AUTH_FAILED) {
            return merge(
              of(loginRejected(error)).pipe(
                tap(() => action.payload.authFailCallback())
              ),
              of(
                pushAlertSnackbar({
                  text: i18n.t('login.loginFail', { ns: 'snackbar' }),
                })
              )
            )
          }
          if (
            error.response.status_code === StatusCode.LOGIN_MFA_CHALLENGE_FAILED
          ) {
            return of(loginRejected(error)).pipe(
              tap(() => action.payload.mfaChallengeFailCallback())
            )
          }
          return of(loginRejected(error))
        })
      )
    )
  )

const logoutEpic: TAppEpic = (action$): Observable<Action> =>
  action$.pipe(
    filter(logout.match),
    tap((action) => {
      clearAuthToken()
      // 避免同時呼叫 logout 時，後面呼叫的 setPathBeforeLogout('/login)
      // 確認 localStorage LOCAL_STORAGE_PATH_BEFORE_LOGOUT 沒有值才重新 set
      if (action.payload.pathBeforeLogout && !getPathBeforeLogout()) {
        setPathBeforeLogout(action.payload.pathBeforeLogout)
      }
      window.location.href = '/login'
    }),
    switchMap((action) =>
      iif(
        () => action.payload.reason === 'manual',
        of(
          pushSuccessSnackbar({
            text: i18n.t('logout.logoutSuccess', { ns: 'snackbar' }),
          })
        ),
        EMPTY
      )
    )
  )

export const setPath = () => {
  if (!getPathBeforeLogout()) {
    setPathBeforeLogout(window.location.pathname + window.location.search)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkUnauthorizedToken = (error: any) => {
  if (error?.response?.status_code === StatusCode.UNAUTHORIZED_TOKEN) {
    // 先 setPathBeforeLogout 再 logout，避免先 logout pathBeforeLogout 會變成 /login
    return (
      setPath(),
      of(
        logout({
          reason: 'expired',
        })
      )
    )
  }
  return EMPTY
}

export const authEpic = combineEpics(loginEpic, logoutEpic)
