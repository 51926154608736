import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { ReactComponent as DDWAlertIcon } from 'assets/ddw/DDW-alert-icon.svg'
import { ReactComponent as DDWTailoredReportsIcon } from 'assets/ddw/DDW-tailored-reports-icon.svg'
import BackgroundImage from 'assets/ddw/risk-monitoring-background-image.jpg'
import { Button, LinkButton } from 'components/Button/Button'
import { PAGE_TITLE_RISK_MONITORING } from 'constants/pageTitle'
import {
  DDW_ALERT_DEMO_ROUTE,
  DDW_ALERT_ROUTE,
  DDW_TAILORED_CHINESE_REPORT_SAMPLE,
  DDW_TAILORED_ENGLISH_REPORT_SAMPLE,
} from 'constants/routeParams'
import { useAppSelector } from 'hooks/useReduxHooks'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { selectAccountSettings } from 'store/slices/account'
import { openMail } from 'utils/mail'

const DDW_MONITOR_KEY = 'deep_dark_web_monitor'

export const RiskMonitoringPage = () => {
  const theme = useTheme()
  const { t } = useTranslation(['ddw', 'component'])

  const accountSettings = useAppSelector(selectAccountSettings)
  const isSubscribeDdwMonitor =
    accountSettings.otherServicesSubscriberGroups?.find(
      (otherServicesSubscriberGroup) =>
        otherServicesSubscriberGroup.key === DDW_MONITOR_KEY
    )

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_RISK_MONITORING}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          background: `url(${BackgroundImage}) lightgray 50% / cover no-repeat`,
        }}
      >
        <Stack sx={{ px: 16, py: 12, color: theme.colors.WHITE, gap: 12 }}>
          <Stack>
            <Typography variant="titleBig">
              {t('entry.ddwRiskMonitoring.title', { ns: 'ddw' })}
            </Typography>
            <Typography variant="bodySmall" sx={{ maxWidth: '45rem', mt: 2 }}>
              <Trans t={t} i18nKey="entry.ddwRiskMonitoring.description">
                Assist clients in monitoring whether leaked credentials on the
                Deep and Dark Web have been used to commit crimes, and
                strengthens monitoring of social media platforms where
                cybercrime is rampant in China.
                <br />
                By notifying monitoring results in a timely manner, enterprise
                organizations can take contingency measures as early as possible
                to effectively reduce the risk of losses caused by credential
                leakage.
              </Trans>
            </Typography>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={openMail}
              sx={{ width: '11.5rem', mt: 6 }}
            >
              {t('contactCta', { ns: 'component' })}
            </Button>
          </Stack>
          <Box sx={{ display: 'flex', gap: 12 }}>
            <Stack sx={{ gap: 2, flex: '1 1 0%' }}>
              <SvgIcon
                sx={{ width: '5rem', height: '5rem' }}
                component={DDWAlertIcon}
                inheritViewBox
              />
              <Stack sx={{ gap: 6 }}>
                <Stack sx={{ gap: 2 }}>
                  <Typography variant="title">
                    {t('entry.ddwAlert.title', { ns: 'ddw' })}
                  </Typography>
                  <Typography variant="bodySmall">
                    <Trans t={t} i18nKey="entry.ddwAlert.description">
                      ThreatVision provides automatic scanning of Deep and Dark
                      Web Risk Monitoring once per week.
                      <br />
                      The monitoring scope covers secret-stealing malware,
                      leaked servers, etc. The relevant monitoring results will
                      be automatically updated on the platform. Email alert
                      notifications will also be sent via email.
                      <br />
                      The platform provides querying of historical monitoring
                      results to assist customers in effective internal risk
                      management and disposal. The historical data can also be
                      downloaded in CSV format.
                    </Trans>
                  </Typography>
                </Stack>
                {isSubscribeDdwMonitor ? (
                  <LinkButton
                    to={`/${DDW_ALERT_ROUTE}`}
                    endIcon={
                      <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                    }
                    sx={{ width: '100%' }}
                  >
                    {t('entry.ddwAlert.checkCta', { ns: 'ddw' })}
                  </LinkButton>
                ) : (
                  <LinkButton
                    to={`/${DDW_ALERT_DEMO_ROUTE}`}
                    endIcon={
                      <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                    }
                    sx={{ width: '100%' }}
                  >
                    {t('entry.ddwAlert.demoCta', { ns: 'ddw' })}
                  </LinkButton>
                )}
              </Stack>
            </Stack>
            <Stack sx={{ gap: 2, flex: '1 1 0%' }}>
              <SvgIcon
                sx={{ width: '5rem', height: '5rem' }}
                component={DDWTailoredReportsIcon}
                inheritViewBox
              />
              <Stack sx={{ gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant="title">
                    {t('entry.ddwTailoredReports.title', { ns: 'ddw' })}
                  </Typography>
                </Box>
                <Typography variant="bodySmall">
                  <Trans t={t} i18nKey="entry.ddwTailoredReports.description">
                    Customized monthly Deep and Dark Web Risk Monitoring
                    reports.
                    <br />
                    In addition to monitoring whether credentials are leaked on
                    the Deep and Dark Web, the monthly report also includes
                    research on encrypted communities, dark web sites, and
                    forums where cybercrimes are active in the Asia-Pacific
                    region. For example, PTT, X (Twitter), Telegram, QQ, Wechat,
                    etc., done by professionals with experience in understanding
                    the trends of Chinese Internet crimes.
                    <br />
                    The report contents are all investigated and verified by
                    TeamT5’s dedicated analyst team, effectively reducing
                    misjudgments and noise. Customers can quickly understand
                    their own risks and handle incidents through professional
                    mitigation recommendations.
                  </Trans>
                </Typography>
                <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flex: '1 1 0%' }}>
                    <LinkButton
                      to={`/${DDW_TAILORED_CHINESE_REPORT_SAMPLE}`}
                      endIcon={
                        <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                      }
                      linkSx={{ width: '100%' }}
                      sx={{ width: '100%' }}
                    >
                      {t('entry.ddwTailoredReports.chineseSampleCta', {
                        ns: 'ddw',
                      })}
                    </LinkButton>
                  </Box>
                  <Box sx={{ display: 'flex', flex: '1 1 0%' }}>
                    <LinkButton
                      to={`/${DDW_TAILORED_ENGLISH_REPORT_SAMPLE}`}
                      endIcon={
                        <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                      }
                      linkSx={{ width: '100%' }}
                      sx={{ width: '100%' }}
                    >
                      {t('entry.ddwTailoredReports.englishSampleCta', {
                        ns: 'ddw',
                      })}
                    </LinkButton>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
