import { Typography, useTheme } from '@mui/material'
import { Dialog } from 'components/Dialog/Dialog'
import { useTranslation } from 'react-i18next'

interface RemoveMFADialogProps {
  open: boolean
  handleClose: () => void
  handleConfirm: () => void
}

export const RemoveMFADialog = ({
  open,
  handleClose,
  handleConfirm,
}: RemoveMFADialogProps) => {
  const { t } = useTranslation(['account'])
  const theme = useTheme()

  const handleDialogClose = () => {
    handleClose()
  }

  const handleDialogConfirm = () => {
    handleConfirm()
  }

  return (
    <Dialog
      open={open}
      handleDialogClose={handleDialogClose}
      title={t('mfa.drawer.removeDialog.title', { ns: 'account' })}
      content={
        <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
          {t('mfa.drawer.removeDialog.description', { ns: 'account' })}
        </Typography>
      }
      confirmButtonText={t('mfa.drawer.removeDialog.confirmCta', {
        ns: 'account',
      })}
      handleConfirmButtonClick={handleDialogConfirm}
    />
  )
}
