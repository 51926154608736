import { Stack, Typography, useTheme } from '@mui/material'
import { Dialog } from 'components/Dialog/Dialog'
import { TextLink } from 'components/Link/Link'
import { TextField } from 'components/TextField/TextField'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { Trans, useTranslation } from 'react-i18next'
import { login, selectLoginLoading } from 'store/slices/auth'
import { openMail } from 'utils/mail'
import * as Yup from 'yup'

interface ILoginOtpDialogProps {
  email: string
  password: string
  open: boolean
  handleClose: () => void
  handleBack?: () => void
}

export const LoginOtpDialog = ({
  email,
  password,
  open,
  handleClose,
  handleBack,
}: ILoginOtpDialogProps) => {
  const { t } = useTranslation(['login', 'component'])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isLoginLoading = useAppSelector(selectLoginLoading)

  const {
    touched,
    errors,
    values,
    setValues,
    handleSubmit,
    handleChange,
    setFieldError,
  } = useFormik({
    initialValues: { mfa_token: '' },
    validationSchema: Yup.object().shape({
      mfa_token: Yup.string().required(
        t('fieldErrorMessage.required', { ns: 'component' })
      ),
    }),
    onSubmit: () => {
      dispatch(
        login({
          ...values,
          email,
          password,
          authFailCallback: () => {},
          mfaChallengeFailCallback: () => {
            setFieldError(
              'mfa_token',
              t('mfaDialog.inputErrorMessage', { ns: 'login' })
            )
          },
        })
      )
    },
  })

  const handleDialogClose = () => {
    setFieldError('mfa_token', undefined)
    setValues({ mfa_token: '' })
    handleClose()
  }

  return (
    <Dialog
      open={open}
      handleDialogClose={handleDialogClose}
      handleDialogBack={handleBack}
      title={t('mfaDialog.title', { ns: 'login' })}
      content={
        <Stack sx={{ gap: 6 }}>
          <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
            {t('mfaDialog.description', { ns: 'login' })}
          </Typography>
          <TextField
            name="mfa_token"
            label={t('mfaDialog.inputLabel', { ns: 'login' })}
            type="text"
            value={values.mfa_token}
            onChange={handleChange}
            error={touched.mfa_token && !!errors.mfa_token}
            helperText={touched.mfa_token && errors.mfa_token}
          />
          <Typography
            variant="body"
            sx={{ color: theme.colors.TEXT_LINK_NORMAL }}
          >
            <Trans t={t} i18nKey="mfaDialog.cannotLogin">
              Can’t login?
              <TextLink size="medium" onClick={openMail} to="">
                Contact support
              </TextLink>
            </Trans>
          </Typography>
        </Stack>
      }
      confirmButtonText={t('mfaDialog.loginCta', { ns: 'login' })}
      confirmButtonLoading={isLoginLoading}
      confirmButtonDisabled={isLoginLoading}
      handleConfirmButtonClick={handleSubmit}
    />
  )
}
