import {
  Avatar,
  Box,
  FormControlLabel,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as DeleteIcon } from 'assets/basicIcons/delete.svg'
import { ReactComponent as EditIcon } from 'assets/basicIcons/edit.svg'
import { ReactComponent as LockIcon } from 'assets/basicIcons/lock.svg'
import { ReactComponent as MoreIcon } from 'assets/basicIcons/more.svg'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { Dialog } from 'components/Dialog/Dialog'
import { DateLabel, TwoToneLabel } from 'components/Label/Label'
import { Menu, MenuItem } from 'components/Menu/Menu'
import { TextArea } from 'components/TextField/TextField'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useAvatarColor } from 'hooks/useAvatarColor'
import { useDateTime } from 'hooks/useDatetime'
import { useImageUrl } from 'hooks/useImageSrc'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  fetchAccountSettings,
  fetchAccountSettingsCancelled,
  selectAccountSettings,
} from 'store/slices/account'
import {
  createComment,
  deleteComment,
  fetchComments,
  selectCommentableId,
  selectCommentableType,
  selectCommentCreating,
  selectCommentLoading,
  selectComments,
  selectCommentUpdating,
  updateComment,
} from 'store/slices/comment'
import { IComment } from 'store/types/entityTypes/comment'
import { ICommentBodyPayload } from 'store/types/slicesTypes/comment'
import { hideScrollBarStyles } from 'theme/scrollbar'

import { UserCommentsLoading } from './UserCommentsLoading'

interface ICommentAvatarProps {
  email: string
  avatar: string | null
  isPrivate?: boolean
}

const CommentAvatar = ({ email, avatar, isPrivate }: ICommentAvatarProps) => {
  const theme = useTheme()
  const avatarBgcolor = useAvatarColor(email)

  const avatarUrl = useImageUrl({ fetchImageUrl: avatar || '' })

  return (
    <Box sx={{ position: 'relative' }}>
      {avatarUrl ? (
        <Avatar src={avatarUrl} />
      ) : (
        <Avatar sx={{ bgcolor: avatarBgcolor }}>
          <Typography variant="reportH1">
            {email && email.slice(0, 1).toLocaleUpperCase()}
          </Typography>
        </Avatar>
      )}
      {isPrivate && (
        <SvgIcon
          sx={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '1.25rem',
            height: '1.25rem',
            borderRadius: '50%',
            color: theme.colors.WHITE_40,
            border: `1px solid ${theme.colors.WHITE_40}`,
            bgcolor: theme.colors.BLACK_95,
          }}
          component={LockIcon}
          inheritViewBox
        />
      )}
    </Box>
  )
}

interface ICommentFormProps {
  replyToId?: string
  hideReplyForm?: () => void
}

const CommentForm = ({ replyToId, hideReplyForm }: ICommentFormProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['component'])
  const { email, avatar } = useAppSelector(selectAccountSettings)

  const commentableId = useAppSelector(selectCommentableId)
  const commentableType = useAppSelector(selectCommentableType)
  const creatingCommentId = useAppSelector(selectCommentCreating)
  const theme = useTheme()
  const [isPrivate, setPrivate] = useState<boolean>(false)
  const [commentInput, setCommentInput] = useState<string>('')

  const handleCheckPrivate = (event: ChangeEvent<HTMLInputElement>) => {
    setPrivate(event.target.checked)
  }

  const handleCommentInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCommentInput(event.target.value)
  }

  const onSubmitSuccess = () => {
    setPrivate(false)
    setCommentInput('')
    if (commentableType) {
      dispatch(fetchComments({ commentableId, commentableType }))
    }

    // 若是 reply to comment，成功後需要隱藏輸入框
    if (replyToId && hideReplyForm) {
      hideReplyForm()
    }
  }

  const handleSubmit = () => {
    if (commentInput && commentableId && commentableType) {
      // 若有 replyToId 就是針對該 comment 回覆，若無 replyToId 就是針對當下物件進行 comment
      dispatch(
        createComment({
          commentableId: replyToId || commentableId,
          commentableType: replyToId ? 'Karen::Comment' : commentableType,
          body: {
            comment: {
              content: commentInput,
              private: isPrivate,
            },
          },
          successCallback: onSubmitSuccess,
        })
      )
    }
  }

  useEffect(() => {
    dispatch(fetchAccountSettings())
    return () => {
      dispatch(fetchAccountSettingsCancelled())
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', gap: 2, pl: replyToId ? 12 : 0 }}>
      <Box>{email && <CommentAvatar email={email} avatar={avatar} />}</Box>
      <Stack sx={{ gap: 1, flexGrow: 1 }}>
        <Typography
          variant="textSmallImportant"
          sx={{ color: theme.colors.WHITE }}
        >
          {email}
        </Typography>
        <TextArea
          value={commentInput}
          onChange={handleCommentInputChange}
          placeholder={t('commentSection.commentPlaceholder', {
            ns: 'component',
          })}
          sx={{ '& .MuiInputBase-inputMultiline': hideScrollBarStyles }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: theme.colors.WHITE,
                  ...theme.typography.textSmallImportant,
                },
              }}
              control={
                <Checkbox checked={isPrivate} onChange={handleCheckPrivate} />
              }
              label={t('commentSection.commentPrivate', { ns: 'component' })}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {replyToId && (
              <Button
                size="large"
                sx={{ width: '10rem' }}
                onClick={hideReplyForm}
              >
                {t('cancelCta', { ns: 'component' })}
              </Button>
            )}
            <Button
              size="large"
              sx={{ width: '10rem' }}
              onClick={handleSubmit}
              variant="contained"
              loading={
                creatingCommentId === commentableId ||
                creatingCommentId === replyToId
              }
            >
              {replyToId
                ? t('commentSection.reply', { ns: 'component' })
                : t('commentSection.submit', { ns: 'component' })}
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

interface ICommentBlockProps extends IComment {
  isSubComment?: boolean
}

const CommentBlock = ({
  id,
  author: { email, avatar },
  date,
  content,
  replyTo,
  isPrivate,
  isSubComment,
  editable,
}: ICommentBlockProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])
  const { getDateTimeStringByTimestampsInSeconds } = useDateTime()
  const dispatch = useAppDispatch()
  const updatingCommentId = useAppSelector(selectCommentUpdating)
  const commentableId = useAppSelector(selectCommentableId)
  const commentableType = useAppSelector(selectCommentableType)
  const [showReplyForm, setShowReplyForm] = useState<boolean>(false)
  const [moreOptionAnchorEl, setMoreOptionAnchorEl] =
    useState<null | HTMLElement>(null)
  const [isEditing, setEditing] = useState<boolean>(false)
  const [editInput, setEditInput] = useState<string>(content)
  const [editPrivate, setEditPrivate] = useState<boolean>(isPrivate)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)

  const handleMoreOptionIconClick = (event: MouseEvent<HTMLDivElement>) => {
    setMoreOptionAnchorEl(event.currentTarget)
  }

  const handleMoreOptionMenuClose = () => {
    setMoreOptionAnchorEl(null)
  }

  const handleReplyClick = () => {
    setShowReplyForm(true)
  }

  const hideReplyForm = () => {
    setShowReplyForm(false)
  }

  const activeEdit = () => {
    setEditing(true)
    handleMoreOptionMenuClose()
  }

  const handleEditInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditInput(event.target.value)
  }

  const handleCheckEditPrivate = (event: ChangeEvent<HTMLInputElement>) => {
    setEditPrivate(event.target.checked)
  }

  // 需要用 redux 的 commentableId/commentableType 而不是此 comment id 來重新抓取 comments
  const handleUpdateComments = () => {
    if (commentableType) {
      dispatch(fetchComments({ commentableId, commentableType }))
    }
  }

  const onEditSuccess = () => {
    setEditing(false)
    handleUpdateComments()
  }

  const handleEditSave = () => {
    const editedComment: ICommentBodyPayload = {
      comment: {
        content: editInput,
        private: editPrivate,
      },
    }
    dispatch(
      updateComment({
        commentableId: String(id),
        body: editedComment,
        successCallback: onEditSuccess,
      })
    )
  }

  const handleEditCancel = () => {
    setEditing(false)
    setEditInput(content)
    setEditPrivate(isPrivate)
  }

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true)
  }

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false)
    handleMoreOptionMenuClose()
  }

  const onDeleteSuccess = () => {
    handleUpdateComments()
  }

  const handleDeleteComment = () => {
    dispatch(
      deleteComment({
        commentableId: String(id),
        successCallback: onDeleteSuccess,
      })
    )
    handleConfirmDialogClose()
  }

  const moreOptionMenuOpen = Boolean(moreOptionAnchorEl)

  return (
    <Box id={String(id)}>
      <Stack sx={{ mb: 4, gap: 4 }}>
        <Box sx={{ display: 'flex', pl: isSubComment ? 12 : 0 }}>
          <Box sx={{ display: 'flex', flexGrow: 1, gap: 2 }}>
            <Box sx={{ flexShrink: 0 }}>
              <CommentAvatar
                email={email}
                avatar={avatar}
                isPrivate={isPrivate}
              />
            </Box>
            <Stack sx={{ flexGrow: 1, gap: 1 }}>
              <Typography
                variant="textSmallImportant"
                sx={{ color: theme.colors.WHITE }}
              >
                {email}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <DateLabel
                  text={getDateTimeStringByTimestampsInSeconds(date)}
                />
                {replyTo && (
                  <TwoToneLabel
                    keyText={t('commentSection.replyTo', { ns: 'component' })}
                    valueText={replyTo}
                    mainColor={theme.colors.WEBSHELL}
                    textColor={theme.colors.WHITE}
                  />
                )}
              </Box>
              {isEditing ? (
                <Stack sx={{ gap: 1 }}>
                  <TextArea
                    value={editInput}
                    onChange={handleEditInputChange}
                    sx={{
                      '& .MuiInputBase-inputMultiline': hideScrollBarStyles,
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      <FormControlLabel
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            color: theme.colors.WHITE,
                            ...theme.typography.textSmallImportant,
                          },
                        }}
                        control={
                          <Checkbox
                            checked={editPrivate}
                            onChange={handleCheckEditPrivate}
                          />
                        }
                        label={
                          <Typography
                            sx={{ color: theme.colors.WHITE }}
                            variant="body"
                          >
                            {t('commentSection.commentPrivate', {
                              ns: 'component',
                            })}
                          </Typography>
                        }
                      />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        size="large"
                        sx={{ width: '10rem' }}
                        onClick={handleEditCancel}
                      >
                        {t('cancelCta', { ns: 'component' })}
                      </Button>
                      <Button
                        size="large"
                        sx={{ width: '10rem' }}
                        variant="contained"
                        onClick={handleEditSave}
                        loading={updatingCommentId === String(id)}
                        disabled={!editInput}
                      >
                        {t('commentSection.submit', { ns: 'component' })}
                      </Button>
                    </Box>
                  </Box>
                </Stack>
              ) : (
                <Typography
                  variant="contentSmall"
                  sx={{
                    color: theme.colors.WHITE,
                    p: 2,
                    whiteSpace: 'pre-line',
                  }}
                >
                  {content}
                </Typography>
              )}

              <Typography
                variant="textLink"
                sx={{ color: theme.colors.TEXT_LINK_NORMAL, cursor: 'pointer' }}
                onClick={handleReplyClick}
              >
                {t('commentSection.reply', { ns: 'component' })}
              </Typography>
            </Stack>
          </Box>
          {editable && (
            <Box>
              <Box onClick={handleMoreOptionIconClick}>
                <SvgIcon
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    border: `1px solid ${theme.colors.WHITE_60}`,
                    borderRadius: 0.25,
                    cursor: 'pointer',
                  }}
                  component={MoreIcon}
                  inheritViewBox
                />
              </Box>
            </Box>
          )}
        </Box>
        {showReplyForm && (
          <CommentForm replyToId={String(id)} hideReplyForm={hideReplyForm} />
        )}
      </Stack>
      <Menu
        anchorEl={moreOptionAnchorEl}
        open={moreOptionMenuOpen}
        handleClose={handleMoreOptionMenuClose}
        PaperPropsSx={{ width: '7.5rem' }}
      >
        <MenuItem handleClick={activeEdit} size="medium">
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <SvgIcon
              sx={{ width: '1rem', height: '1rem' }}
              component={EditIcon}
              inheritViewBox
            />
            {t('commentSection.edit', { ns: 'component' })}
          </Box>
        </MenuItem>
        <MenuItem handleClick={handleConfirmDialogOpen} size="medium">
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <SvgIcon
              sx={{ width: '1rem', height: '1rem' }}
              component={DeleteIcon}
              inheritViewBox
            />
            {t('commentSection.delete', { ns: 'component' })}
          </Box>
        </MenuItem>
      </Menu>
      <Dialog
        open={confirmDialogOpen}
        handleDialogClose={handleConfirmDialogClose}
        title={t('deleteCommentDialog.title', { ns: 'component' })}
        description={t('deleteCommentDialog.description', { ns: 'component' })}
        confirmButtonText={t('commentSection.delete', { ns: 'component' })}
        handleConfirmButtonClick={handleDeleteComment}
      />
    </Box>
  )
}

export const UserComments = () => {
  const [searchParams] = useSearchParams()
  const commentId = searchParams.get(SearchParamKey.COMMENT_ID)
  const flattenComments = useAppSelector(selectComments)
  const isCommentLoading =
    useAppSelector(selectCommentLoading) && flattenComments.length === 0

  // 從後端 comment letter 導頁過來，需要根據 commentId 捲動到特定 comment
  useEffect(() => {
    if (commentId && flattenComments.length > 0 && !isCommentLoading) {
      const commentTarget = document.getElementById(commentId)
      if (commentTarget) {
        commentTarget.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [flattenComments, isCommentLoading])

  return (
    <Stack sx={{ gap: 4 }}>
      {isCommentLoading ? (
        <UserCommentsLoading />
      ) : (
        <>
          <CommentForm />
          <Box>
            {flattenComments.map((comment) => {
              const { comments } = comment
              const showSubComments = comments.length > 0
              return (
                <Box key={comment.id}>
                  <CommentBlock {...comment} />
                  {showSubComments &&
                    comments.map((subComment) => (
                      <CommentBlock
                        key={subComment.id}
                        isSubComment
                        {...subComment}
                      />
                    ))}
                </Box>
              )
            })}
          </Box>
        </>
      )}
    </Stack>
  )
}
