import { Box, useTheme } from '@mui/material'
import { PAGE_TITLE_ACCOUNT } from 'constants/pageTitle'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  fetchAccountSettings,
  fetchAccountSettingsCancelled,
  fetchAccountSettingsOptions,
  fetchAccountSettingsOptionsCancelled,
  selectAccountSettings,
  selectAccountSettingsOptions,
} from 'store/slices/account'

import { Account } from './AccountComponents/Account'
import { API } from './AccountComponents/API'
import { DDWRiskMonitoring } from './AccountComponents/DDWRiskMonitoring'
import { DefaultTLPSetting } from './AccountComponents/DefaultTLPSetting'
import { Group } from './AccountComponents/Group'
import { MFASettings } from './AccountComponents/MFA/MFASettings'
import { Quota } from './AccountComponents/Quota'
import { ReportLanguage } from './AccountComponents/ReportLanguage'
import { RFI } from './AccountComponents/RFI'
import { SubscriptionIoC } from './AccountComponents/SubscriptionIoC'
import { SubscriptionReport } from './AccountComponents/SubscriptionReport'
import { SystemLanguage } from './AccountComponents/SystemLanguage'
import { TimeZone } from './AccountComponents/TimeZone'

export const AccountPage = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const accountSettings = useAppSelector(selectAccountSettings)
  const accountSettingsOptions = useAppSelector(selectAccountSettingsOptions)

  useEffect(() => {
    dispatch(fetchAccountSettings())
    dispatch(fetchAccountSettingsOptions())

    return () => {
      dispatch(fetchAccountSettingsCancelled())
      dispatch(fetchAccountSettingsOptionsCancelled())
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_ACCOUNT}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(35rem, 1fr))',
            p: 1,
            columnGap: 1,
            rowGap: 0.5,
          }}
        >
          <Account {...accountSettings} />
          <Group {...accountSettings} />
          <SubscriptionReport {...accountSettings} />
          <Box sx={{ display: 'grid', gap: 1 }}>
            <SubscriptionIoC {...accountSettings} />
            <DDWRiskMonitoring {...accountSettings} />
          </Box>
          <Quota {...accountSettings} />
          <RFI />
          <DefaultTLPSetting {...accountSettings} />
          <API />
          <ReportLanguage
            accountSettings={accountSettings}
            accountSettingsOptions={accountSettingsOptions}
          />
          <SystemLanguage
            accountSettings={accountSettings}
            accountSettingsOptions={accountSettingsOptions}
          />
          <TimeZone
            accountSettings={accountSettings}
            accountSettingsOptions={accountSettingsOptions}
          />
          <MFASettings />
        </Box>
      </Box>
    </>
  )
}
