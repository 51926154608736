import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { Button } from 'components/Button/Button'
import { TextField } from 'components/TextField/TextField'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { login, selectLoginLoading } from 'store/slices/auth'
import * as Yup from 'yup'

import { LoginStatus, STATUS_KEY } from '../config'
import { LoginOtpDialog } from '../LoginOtpDialog'

const loginFormInitialValues = { email: '', password: '' }

export const LoginForm = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation(['login', 'component'])
  const dispatch = useAppDispatch()
  const isLoginLoading = useAppSelector(selectLoginLoading)
  const [openOtpDialog, setOpenOtpDialog] = useState(false)

  const handleOpenOtpDialog = () => {
    setOpenOtpDialog(true)
  }

  const handleCloseOtpDialog = () => {
    setOpenOtpDialog(false)
  }

  const loginFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('fieldErrorMessage.invalidEmail', { ns: 'component' }))
      .required(t('fieldErrorMessage.required', { ns: 'component' })),
    password: Yup.string().required(
      t('fieldErrorMessage.required', { ns: 'component' })
    ),
  })

  const { values, handleChange, handleSubmit, touched, errors, setFieldError } =
    useFormik({
      initialValues: loginFormInitialValues,
      validationSchema: loginFormSchema,
      onSubmit: () => {
        dispatch(
          login({
            ...values,
            authFailCallback: () => {
              setFieldError('email', ' ')
              setFieldError('password', ' ')
            },
            mfaChallengeFailCallback: () => handleOpenOtpDialog(),
          })
        )
      },
    })

  const handleResetPasswordClick = () => {
    navigate(`?${STATUS_KEY}=${LoginStatus.RESET_PASSWORD_REQUEST}`)
  }

  return (
    <form onSubmit={handleSubmit} data-testid={LoginStatus.ANONYMOUS}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '20rem',
        }}
      >
        <Typography
          variant="titleBig"
          sx={{ color: theme.colors.TEXT_LINK_NORMAL, mb: 6 }}
        >
          {t('loginForm.title', { ns: 'login' })}
        </Typography>
        <Box sx={{ mb: 8, width: '100%' }}>
          <TextField
            name="email"
            label={t('loginForm.mailInputLabel', { ns: 'login' })}
            InputLabelProps={{ color: 'primary' }}
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </Box>
        <Box sx={{ mb: 8, width: '100%' }}>
          <TextField
            name="password"
            label={t('loginForm.passwordInputLabel', { ns: 'login' })}
            type="password"
            InputLabelProps={{ color: 'primary' }}
            value={values.password}
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
        </Box>
        <Button
          sx={{
            mb: 6,
            width: '100%',
          }}
          size="large"
          type="submit"
          loading={!openOtpDialog && isLoginLoading}
          disabled={!openOtpDialog && isLoginLoading}
          endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
        >
          {t('loginForm.loginButtonText', { ns: 'login' })}
        </Button>
        <Button variant="textColor" onClick={handleResetPasswordClick}>
          {t('loginForm.resetPasswordLink', { ns: 'login' })}
        </Button>
        <LoginOtpDialog
          email={values.email}
          password={values.password}
          open={openOtpDialog}
          handleClose={handleCloseOtpDialog}
        />
      </Box>
    </form>
  )
}
